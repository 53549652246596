.docuList {
    flex-basis: 100%;
    padding: 36px;
    width: 97%;
    background: white;
    margin: 0px 25px 25px 25px;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px;
}

.docuListHeader {
    flex-basis: 100%;
    width: 90%;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-left: 25px;
    margin-right: 28px;
}

.tableHeader {
    display: flex;
    justify-content: space-between;
    flex-basis: 5%;
    margin-left: 24px;
    margin-right: 24px;
}

.tableBody {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    height: 99%;
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px;
    margin-left: 24px;
    margin-right: 24px;
    border-radius: 10px;
}

p {
    margin-top: 0.7rem;
}

.viewList {
    margin-left: auto;
    display: flex;
    max-height: 38px;
}

.viewList img {
    padding: 0px 24px;
}
.dropdown-toggle::after {
    margin-left: 1.255em;
    display: none;
}
#generic_table {
    height: 90%;
    width: 100% !important;
    margin-left: 0 !important;
}
@media screen and (width: 1280px) {
    #generic_table {
        height: 89%;
        width: 100% !important;
        margin-left: 0 !important;
    }
}

@media screen and (width: 1440px) {
    #generic_table {
        height: 90%;
        width: 100% !important;
        margin-left: 0 !important;
    }
}

@media screen and (width: 1920px) {
    #generic_table {
        height: 98%;
        width: 100% !important;
        margin-left: 0 !important;
    }
}
