.pendingStepContainer {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ececec;
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px;
}

.delayedStepContainer {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background: rgba(252, 0, 0, 0.04); */
    /* border: 3px solid #ccb800; */
    box-sizing: border-box;
    border-radius: 10px;
}

.doneStepContainer {
    padding: 8px;
    /* background: #f4fbff; */
    /* border: 3px solid #02b734; */
    box-sizing: border-box;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contentRow {
    display: flex;
    justify-content: space-between;
    min-height: 24px;
}

.addGenericContainer {
    padding: 25px 70px 25px 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f4fbff;
    margin-left: 25%;
    margin-right: 25%;
    border: 1px dashed #000000;
    border-radius: 10px;
}

#title {
    font-size: 15px;
    line-height: 20px;
    /* padding-left: 12px; */
}

.content {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: -0.022em;
    color: #333333;
}

.contentChip {
    margin-top: -4px !important;
}
