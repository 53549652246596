.dropdown_Btn {
    display: flex !important;
    align-items: center !important;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    background: white !important;
    border: aliceblue;
    height: 35px !important;
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: 11.5%;
    flex-basis: 11.5%;
}

.dropdown_Btn_active {
    border: 1.5px solid dodgerblue;
}

@media only screen and (min-width: 1000px) and (max-width: 1250px) {
    .dropdown_Btn {
        width: 12%;
        flex-basis: 12%;
    }
}

@media only screen and (min-width: 1251px) and (max-width: 1700px) {
    .dropdown_Btn {
        width: 12%;
        flex-basis: 9.5%;
    }
}

@media only screen and (min-width: 1701px) and (max-width: 1850px) {
    .dropdown_Btn {
        width: 12%;
        flex-basis: 9.9%;
    }
}

@media only screen and (min-width: 1851px) {
    .dropdown_Btn {
        width: 12%;
        flex-basis: 13%;
    }
}

.dropdown_dashboard {
    width: 142px;
}

.Time_filter {
    width: 10rem;
}

#selectGenericNameDropdown .MuiPopover-paper {
    max-height: 50vh !important;
}

@media (min-width: 1200px) and (max-width: 2052px) {
    .flowBox {
        width: 200px;
    }
}

@media (min-width: 2052px) and (max-width: 2732px) {
    .flowBox {
        width: 254px;
    }
}
@media (min-width: 2733px) and (max-width: 4102px) {
    .flowBox {
        width: 350px;
    }
}
@media (min-width: 4103px) and (max-width: 5464px) {
    .flowBox {
        width: 520px;
    }
}
