.alertlineTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #404040;
    padding: 5px;
}
.MuiTimelineContent-root.MuiTimelineItem-content.makeStyles-timelineContentContainer-280 {
    margin: -52px -25px 0px;
}
ul#DC_TimeLine li.MuiTimelineItem-root.MuiTimelineItem-alignAlternate {
    min-height: 180px !important;
}

.controlTowerTopbar {
    background: #fbfbfb;
    display: flex;
    align-items: center;
    height: 3rem;
    justify-content: space-between;
}

.TopbarOptionsEnabled {
    padding: 10px;
    cursor: pointer;
    color: black;
    font-weight: 600;
}

.TopbarOptionsDisabled {
    padding: 10px;
    cursor: context-menu;
    color: grey;
    font-weight: 300;
}

.timelineWrapper {
    height: "35vh";
    overflow-x: "auto";
    overflow-y: "auto";
    border: "1px solid black";
    padding: "0px 10px 0px";
    margin-bottom: 10px;
}

.timelineDisplayNone {
    display: none;
}

.timelineExpandButton {
    height: 25px;
    width: 25px;
    background: #e3f2fd !important;
    border-radius: 50%;
    padding: 4px 8px;
    /* padding-left: 8px; */
}

.timelineConnector {
    color: #404040;
    height: 50px;
}

.fileDetailsButton {
    height: 25px;
    width: 25px;
    cursor: pointer;
    background: #e3f2fd !important;
    border-radius: 50%;
    padding: 8px;
    position: relative;
    top: 5px;
}

.settingsCTHeight {
    height: 100vh;
}

.timeline_dashboard .MuiTimelineItem-root {
    min-height: 13rem !important;
}

.documentCardTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */
    color: #000000;
    text-align: left;
}

.documentCardSubtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 9px;
    color: #000000;
    text-align: left;
    padding-left: 2px;
    padding-right: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 10px;
}

.leftIcon {
    height: 20px;
    width: 20px;
}

.documentCardContainer {
    height: 100%;
    width: 100%;
}
.documentTitleCol {
    display: flex;
    align-items: center;
}

.documentSubtitleRow {
    height: 2.5rem;
}
