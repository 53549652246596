.btn-container {
    display: flex;
    align-items: center;
    top: 24px;
    left: 35px;
    padding: 6px 12px 6px 12px;
    border-radius: 4px;
    gap: 8px;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.46000000834465027px;
    text-align: left;
    transition: 0.5s;
}

.only-arrow {
    /* width: 42px;
height: 42px; */
    top: 924px;
    left: 64px;
    padding: 8px;
    border-radius: 4px;
    gap: 8px;
    transition: 0.5s;
}

.primary {
    border: none;
    color: #ffffff;
    background-color: #0b5cd7;

    .left-arrow {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(143deg)
            brightness(106%) contrast(106%);
    }
}

.primary:hover {
    background: #044dba;
}

.primary:focus {
    background-color: #044dba;
    border: 1px solid #044dba;
    box-shadow: 0 0 0 3px #b6cff3;
    /* two layers of box-shadow for double borders */
}

.primary:active {
    background: #043c8f;
    border-radius: 4px;
}

.secondary {
    border: none;
    color: #000000;
    background-color: #ffffff;

    .left-arrow {
        filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(335deg)
            brightness(104%) contrast(107%);
    }
}

.secondary:hover {
    background: #f4f7fb;
}

.secondary:focus {
    border: 1px solid #0b5cd7;
    box-shadow: 0 0 0 3px #b6cff3;
    /* two layers of box-shadow for double borders */
    background-color: #eaeef5;
}

.secondary:active {
    background: #d7deea;
    border-radius: 4px;
}

.outlined {
    border: 1px solid #000000;
    color: #0a0a0a;
    background-color: #ffffff;

    .left-arrow {
        filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(335deg)
            brightness(104%) contrast(107%);
    }
}

.outlined:hover {
    background: #f4f7fb;
}

/* .outlined:focus {
    border: 3px solid #B6CFF3
} */
.outlined:focus {
    background-color: #eaeef5;
    box-shadow: 0 0 0 3px #b6cff3;
    /* two layers of box-shadow for double borders */
}

.outlined:active {
    background: #d7deea;

    border-radius: 4px;
}

.danger {
    border: none;
    color: #ffffff;
    background-color: #ff0000;

    .left-arrow {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(143deg)
            brightness(106%) contrast(106%);
    }
}

.danger:hover {
    background: #a50d0d;
}

.danger:focus {
    background: #a50d0d;
    border: 1px solid #0b5cd7;
    box-shadow: 0 0 0 3px #b6cff3;
    /* two layers of box-shadow for double borders */
}

.danger:active {
    background: #5f0707;
    border-radius: 4px;
}

.ghost {
    border: none;
    color: #2484c6;
    background-color: #ffffff;

    .left-arrow {
        filter: invert(39%) sepia(96%) saturate(620%) hue-rotate(169deg)
            brightness(92%) contrast(87%);
    }
}

.ghost:hover {
    color: #044dba;

    .left-arrow {
        filter: invert(19%) sepia(94%) saturate(2475%) hue-rotate(209deg)
            brightness(90%) contrast(97%);
    }
}

.ghost:focus {
    background-color: #0b5cd733;
    color: #124e7d;

    .left-arrow {
        filter: invert(23%) sepia(96%) saturate(596%) hue-rotate(168deg)
            brightness(91%) contrast(95%);
    }
}

.ghost:active {
    color: #043c8f;
    background-color: #ffffff;

    .left-arrow {
        filter: invert(13%) sepia(97%) saturate(2426%) hue-rotate(209deg)
            brightness(95%) contrast(97%);
    }
}

.arrow-ghost {
    border: none;
    background-color: #ffffff;

    .left-arrow {
        filter: invert(66%) sepia(6%) saturate(979%) hue-rotate(180deg)
            brightness(86%) contrast(85%);
    }
}

.arrow-ghost:hover {
    .left-arrow {
        filter: invert(32%) sepia(11%) saturate(683%) hue-rotate(181deg)
            brightness(94%) contrast(93%);
    }
}

.arrow-ghost:focus {
    background-color: #0b5cd733;

    .left-arrow {
        filter: invert(19%) sepia(90%) saturate(2359%) hue-rotate(207deg)
            brightness(97%) contrast(97%);
    }
}

.arrow-ghost:active {
    background-color: #ffffff;

    .left-arrow {
        filter: invert(9%) sepia(9%) saturate(1688%) hue-rotate(185deg)
            brightness(93%) contrast(86%);
    }
}

.disable {
    border: 1px solid #d7deea;
    background: #eaeef5;
    color: #9e9e9e;

    .left-arrow {
        filter: invert(64%) sepia(6%) saturate(12%) hue-rotate(23deg)
            brightness(99%) contrast(87%);
    }
}
