.text_msg_body {
    font-family: Montserrat;
    font-weight: 500;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    float: left;
    padding: 2px;
    width: 100%;
    min-width: 8rem;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}
