.archiveRowTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* text-primary */
    color: #404040;
}

.MuiDialogActions-root.archeive-btn.MuiDialogActions-spacing {
    padding: 10px 23px;
    margin-bottom: 12px;
}

.archive-message {
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.archiveRowTilalert {
    text-overflow: ellipsis !important;
    white-space: inherit !important;
    overflow: hidden;
    width: 200px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #404040;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
}

.close {
    width: 49px;
    height: 39px;
    /* left: 383px;
    top: 23px;
    margin-top: -58px; */
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    opacity: 0.7;
}

.archived_date {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* text-primary */
    color: #404040;
}

.restoreAll_btn {
    box-shadow: 0px 0px 2px rgb(0 0 0 / 12%), 0px 2px 2px rgb(0 0 0 / 24%);
    font-weight: 600;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: white !important;
    height: 2.2rem;
    background: #0798ea !important;
    border-radius: 10px !important;
    font-size: 0.8rem;
    line-height: 21px;
}

.archiveHeaderRow {
    justify-content: space-between;
    width: 18rem;
}

.trashHeaderRow {
    justify-content: space-between;
    width: 18rem;
}

.archiveAlertIcon {
    /* height: 20px;
    width: 20px;
    margin-top: 0.3rem;
     */
    max-width: 2rem;
}

.archive_main_container {
    height: 100%;
}

.archive_header {
    background: white;
    position: sticky;
    top: 0;
    height: 2.5rem;
}

#responsive-dialog-title {
    font-family: " Montserrat";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 9px;
    padding-top: 24px;
}

.archive_header_container {
    height: 10%;
    overflow-y: auto;
    /* padding-left: 2rem; */
    padding-right: 2rem;
    margin-left: 1rem;
    margin-right: 1.5rem;
    background: #ffffff;
}

.archive_data_container {
    height: 78%;
    overflow-y: auto;
}

.archive_cardHeader_title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    /* or 214% */
    letter-spacing: 0.15px;
    /* Black / High Emphasis */
    color: rgba(0, 0, 0, 0.87);
}

.archive_main_data {
    height: 82%;
    overflow-y: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    background: #ffffff;
    box-shadow: 1px 1px 20px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.workspaceIconRow {
    border-radius: 13px;
    margin-bottom: 27px;
    margin-top: 20px;
}

.workText {
    font-family: "Montserrat";
    text-align: center;
    padding-bottom: 0px;
    font-size: 28px;
    padding-top: 0px;
}

.workText.Archieve {
    font-family: "Montserrat";
    text-align: center;
    font-size: 28px;
    padding-top: 0px;
}

.emptyArchiveContainer {
    height: 100%;
    margin: 1rem;
    display: flex;
    align-items: center;
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px;
}

.emptyTrashContainer {
    height: 100%;
    margin: 1rem;
    display: flex;
    align-items: center;
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px;
}

span.subText {
    display: flex;
    justify-content: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.sText {
    text-align: center;
    font-size: 28px;
    font-family: "Montserrat";
    padding-top: 0px;
    padding-bottom: 0px;
}

.Archive_space {
    padding: 160px 85px 20px 85px;
}
