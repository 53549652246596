.docChainContainer .react-flow__node.react-flow__node-selectorNode.selected.selectable {
    background: none !important;
    border: 1.5px solid yellow !important;
}
.react-flow__node.react-flow__node-selectorNode.selectable {
    background: none !important;
}
.docChainContainer .react-flow__node {
    cursor: grab !important;
}
