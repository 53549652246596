

#file-name {
    text-overflow: ellipsis;
}

.react-grid-Grid {
    min-height: 400px !important;
}

.react-grid-Canvas {
    height: 100% !important;
}

.docViewContainer {
    height: 68vh;
}
