li.MuiTimelineItem-root.MuiTimelineItem-alignAlternate {
    height: 220px;
}
div#\31 234 {
    padding: 16px 16px;
}

.MuiTimelineContent-root.MuiTimelineItem-content.makeStyles-timelineContentContainer-280 {
    margin: -52px -25px 0px;
}
.MuiPaper-root.makeStyles-root-3.MuiPaper-elevation1.MuiPaper-rounde {
    margin-left: 1px;
    margin-top: -4px;
}
table.MuiTable-root.makeStyles-table-5.MuiTable-stickyHeader {
    height: 100%;
}
.MuiPaper-root.jss3.MuiPaper-elevation1.MuiPaper-rounded {
    margin-left: 0;
}
.MuiTableContainer-root.makeStyles-container-4 {
    max-width: 100% !important;
    height: 100%;
}
.MuiPaper-root.makeStyles-root-3.MuiPaper-elevation1.MuiPaper-rounded {
    margin-top: -6px;
    margin-left: 1px;
}

.MuiPaper-root.makeStyles-root-283.MuiPaper-elevation1.MuiPaper-rounded {
    width: 399px;
    margin-top: -10px;
}
