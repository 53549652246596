.containerHeader {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #404040;
    margin-bottom: 26px;
}

.containerSubHeader {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #404040;
}

.buttonText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding-left: 6px;
}
.icon {
    height: 15px;
    height: 15px;
}
