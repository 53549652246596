.alertBody {
    min-height: 5rem;
    padding: 1rem !important;
}

.alertDiv {
    /* background: #FFFFFF; */
    /* box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04); */
    /* border-radius: 28px; */
    /* margin-bottom: 1.5rem; */
    /* display: flex; */
    /* justify-content: space-between; */
    background: #ffffff;
    border: 0.5px solid #f5f5f5;
    margin-bottom: 0.5rem;
    /* border-style: hidden solid solid solid; */
    box-shadow: 0px 1px 2px 0px #0000001f;
    padding: 16px;
    /* box-shadow: 0px 1px 10px rgb(0 0 0 / 25%); */
    border-radius: 4px;
}

.cursor_pointer {
    cursor: pointer;
}

.bootomArrow {
    position: sticky;
    bottom: 0;
    background: transparent;
    height: 3rem;
}

.borderGreen {
    border-left: 5px solid #65bdc0;
}

.borderRed {
    border-left: 5px solid #ff0000;
}

.borderYellow {
    border-left: 5px solid #d1bc00;
}

.listLeftIcon {
    height: 1.5rem;
    width: 1.5rem;
}

.listHeader {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #404040;
}

.listBody {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: #000000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}

.addAlertBtn {
    background: #0798ea !important;
    color: #ffffff !important;
    margin: 20px !important;
    border-radius: 10px !important;
    padding: 8px !important;
    width: inherit !important;
}

.addContactBtn {
    background: #0798ea;
    color: #ffffff;
    margin: 20px;
    border-radius: 10px;
}
