.form-controls input,
.form-controls.error input,
.form-controls.success input {
    width: 95%; /* Set fixed width */
    height: 36px; /* Set fixed height */
    padding: 6px 12px; /* Padding for top, bottom, left, right */
    gap: 4px; /* Gap for space between elements */
    border-radius: 4px; /* Rounded only at the top-left corner */
    border: 1px solid #d7deea; /* Updated border style */
    background: #ffffff; /* Background color */
    opacity: 1; /* Opacity to make it visible */
    box-shadow: 1px 2px 2px 0px #21242c1f inset; /* Inner shadow */
    font-family: "Montserrat"; /* Font family */
    font-size: 14px; /* Font size */
    font-weight: 400; /* Font weight */
    line-height: 20px; /* Line height */
    letter-spacing: -0.006em; /* Letter spacing */
    text-align: left; /* Text alignment */
}

.form-controls input:focus,
.form-controls.error input:focus,
.form-controls.success input:focus {
    border: 1px solid #0b5cd7;
    box-shadow: 0 0 0 3px #b6cff3;
    background-color: #eaeef5;
}
.form-controls input:hover {
    background-color: #f4f7fb;
    border: 1px solid #0b5cd7;
}
/* .form-controls.success input {
    border: 1px solid #197624;
} */
.form-controls.error input {
    border: 1px solid #ff0000;
}
.form-controls.filled input {
    background-color: #f4f7fb;
}
input[type="text"]:disabled {
    color: #b7becd;

    font-size: 14px;
}

.form-controls {
    position: relative;
}

.input-container {
    height: 36px;
    position: relative;
}

.icon {
    position: absolute;
    left: 162px;
    top: 50%;
    transform: translateY(-50%);
}
