#dropdown_selectedValue_alert {
    color: rgb(15, 15, 15);
    background-color: #fff;
    border: solid 1px rgb(192, 190, 190);
    box-shadow: rgb(109, 107, 107);
    text-align: left;
    margin-left: -132px;
    width: 428px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

textarea#addNewWorkspaceAlertBody {
    margin-top: 23px;
    margin-left: -139px;
    margin-right: -92px;
    border-radius: 10px;
    border: solid 1px rgb(192, 190, 190) !important;
}
#addAlertModal textarea {
    margin-top: 25px;
    margin-left: -190px;
    margin-right: -160px;
    border-radius: 10px;
    border: solid 1px rgb(192, 190, 190) !important;
}
#editAlertModal textarea {
    margin-top: 25px;
    margin-left: -124px;
    margin-right: -235px;
    border-radius: 10px;
    border: solid 1px rgb(192, 190, 190) !important;
}
