.mainTitle {
    height: 35px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #404040;
    white-space: pre;
}

.subTitle {
    width: 362.45px;
    height: 24px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.tableTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* display: flex;
    align-items: center; */
    color: rgba(0, 0, 0, 0.87);
}

.spacesTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    color: #404040;
}

.addLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #404040;
    line-height: 24px;
    cursor: pointer;
}

.doLeft {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.MuiIconButton-root:hover {
    background-color: transparent !important;
}

.heighlightCard {
    border: 3px solid #02c2ff;
}

.homeAlertCard {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 5px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 28px;
}

.workspaceAlertCard {
    width: 85%;
    height: 100%;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 5px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 12%;
}

.spaceCard {
    /* width: 127px;
    height: 136px;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 5px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 28px;
    /* margin-right: 10px; */
    /* margin-left: 10px; */
    /* margin-top: 15px; */
    width: 85%;
    height: 85%;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 5px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 15%;
}

.titleHeaderTitle {
    font-size: 10px;
    font-weight: 600;
}

.alertCard-body {
    height: 100%;
    display: flex;
    overflow-wrap: anywhere;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: Montserrat;
    overflow: auto;
}

.spaceCard-header {
    padding: 10px;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: none;
    text-align: end;
    font-size: 20px;
    padding-bottom: 0px;
}

.spaceCard-header:hover {
    background: transparent !important;
}

.spaceCard_body_text {
    overflow: hidden;
    text-align: center;
    margin: 0;
    text-overflow: ellipsis;
    overflow-wrap: initial;
    white-space: pre;
}

.spaceCard-body {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: Montserrat;
    overflow-wrap: anywhere;
    overflow: auto;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.userLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #404040;
    margin-left: 10px;
    margin-right: 14px;
}

.spaceCardSection {
    background: #fbfbfb;
    box-shadow: 0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 28px;
    width: 100% !important;
    height: 65%;
    margin-bottom: 1rem;
    margin-top: 2.5rem;
}

.spaceCardSectionWithAlert {
    background: #fbfbfb;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 28px;
    width: 100% !important;
    height: 65%;
    /* margin-left: 45px; */
    margin-bottom: 25px;
}

.listAlertSection {
    background: #fafafa;
    box-shadow: 0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 10px;
    width: 95% !important;
    height: 75vh;
    /* overflow: hidden; */
    margin-bottom: 1rem;
    padding-left: 0rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    margin-left: 1rem;
}

.listAlertSection:hover {
    overflow: auto;
}

.alertSection {
    background: #fbfbfb;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 28px;
    width: 100% !important;
    height: 65%;
    margin-bottom: 25px;
    /* margin-left: 45px; */
}

.alertSection .carousel-item.active {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* padding-top: 1.5rem;*/
    /* padding-bottom: 1rem; */
    height: 100% !important;
}

.editLabel {
    margin-left: 5px;
    font-size: 12px;
}

.rightSection {
    background: #fbfbfb;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 28px;
    width: 482px;
    height: 393px;
}

.titleSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 10vh;
    top: 0px;
    position: sticky;
    z-index: 1000;
}

.titleIcon {
    height: 18px !important;
    width: 18px !important;
    /* margin-top: 0.5rem !important; */
    /* margin-left: 10px; */
}
.titleIcons {
    height: 18px !important;

    /* margin-top: 0.5rem !important; */
    /* margin-left: 10px; */
}
.titleText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    /* margin-top: 0.5rem; */
    color: #0d1c2e;
}

.middleSection {
    height: 2rem;
    width: 100%;
}

.btnLabel {
    height: 25px;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #0798ea !important;
    border-radius: 4px !important;
    margin-bottom: 8px !important;
    margin-top: 8px !important;
}

.btnLabel:hover {
    text-decoration: underline;
}

.btnArrowImg {
    height: 10px;
    padding-right: 8px;
}

.btnArrowSlider {
    height: 30px;
    width: 20px;
}

.viewFSbtn {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #2196f3;
    background: #e3f2fd;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    margin-top: 20px;
}

.p-10 {
    padding: 10px !important;
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3% !important;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

.alertSection .carousel-inner {
    /* padding-top: 1rem;
    padding-bottom: 2rem; */
    height: 100%;
}

.spaceCardSectionWithAlert .carousel-inner {
    height: 100%;
}

.spaceCardSection .carousel-inner {
    height: 100%;
}

.spaceCardSection .carousel-item.active {
    height: 100%;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.carousel-control-prev {
    left: 10px;
}

.carousel-control-next {
    right: 10px;
}

.MuiTableBody-root {
    display: table-row-group;
    background: white !important;
}

.spaceTableDiv {
    height: 75%;
}

.spaceTableDiv .MuiTableContainer-root {
    width: 100%;
    height: 76%;
    overflow-y: auto;
    overflow-x: auto;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: #fbfbfb !important;
    border-top-left-radius: 28px !important;
    border-top-right-radius: 28px !important;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04) !important;
}

.spaceTableDiv .MuiTablePagination-root {
    overflow: auto;
    background: #fbfbfb !important;
    font-size: 0.875rem;
    border-bottom-left-radius: 28px !important;
    border-bottom-right-radius: 28px !important;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04),
        0 0 1px rgba(0, 0, 0, 0.04) !important;
}

.spaceTableDiv .MuiTablePagination-selectRoot {
    margin-left: 8px;
    margin-right: 30% !important;
}

.spaceTableDiv .MuiTablePagination-toolbar {
    min-height: 0.5rem !important;
    padding: 0;
}

.MuiTablePagination-spacer {
    flex: none;
}

.MuiPaper-elevation1 {
    background: #fbfbfb !important;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.MuiTableContainer-root {
    width: 100%;
    overflow-x: auto;
    padding-top: 15px;
}

.MuiTableCell-head {
    color: rgb(33 33 33) !important;
    font-weight: 550;
    line-height: 1.5rem;
    padding-bottom: 5px !important;
}

/* .MuiTableCell-root {
    height: 42px !important;
} */

.newFontMontserrat {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
}

/* Alert  */

.alertHeaderTitle {
    text-align: center;
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #404040;
}

.alertHeaderIcon {
    height: 16px;
    width: 16px;
}

/* popover */

.MuiPopover-paper {
    min-width: 170px !important;
    padding-top: 10px !important;
    border-radius: 10px;
}

.ht-50 {
    height: 50% !important;
}

.spaceCardSectionWithAlert .carousel-item {
    height: 100%;
}

/* @media only screen and (max-width: 1080px) { */

/* @media (min-width: 1380px) and (max-width: 2200px) {
    .spaceCard {
        width: 75%;
        height: 70%;
        background: #ffffff;
        box-sizing: border-box;
        box-shadow: 5px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 28px;
        margin-top: 15px;
    }
    .spaceCardSectionWithAlert .carousel-item {
        height: 100%;
    }
    .ht-50 {
        height: 50% !important;
    }
    .homeAlertCard {
        width: 85%;
        height: 90%;
        background: #ffffff;
        box-sizing: border-box;
        box-shadow: 5px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%), 0px 0px 1px rgb(0 0 0 / 4%);
        border-radius: 28px;
    }
    .alertSection .carousel-item.active {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        height: 100% !important;
    }
} */
