div#add_Document_Modal {
    left: 20%;
    top: -4%;
}
@media (min-width: 1080px) and (max-width: 1149px) {
    div#add_Document_Modal {
        left: 20%;
        top: -17%;
    }
}
@media (min-width: 1150px) and (max-width: 1269px) {
    div#add_Document_Modal {
        left: 22%;
        top: -17%;
    }
}
@media (min-width: 1270px) and (max-width: 1439px) {
    div#add_Document_Modal {
        left: 25%;
        top: -17%;
    }
}
/* @media (min-width: 1423px) {
    div#add_Document_Modal {
        left: 31%;
        top: -17%;
    }
} */
@media (min-width: 1440px) and (max-width: 1850px) {
    div#add_Document_Modal {
        left: 27%;
        top: -17%;
    }
}
@media (min-width: 1851px) {
    div#add_Document_Modal {
        left: 31%;
        top: -17%;
    }
}
#pg-viewer {
    height: 68vh !important;
    text-align: center;
}
#react-doc-viewer {
    height: 68vh !important;
    padding: 30px;
    width: 100%;
    background: #fff;
    margin: auto;
    margin-bottom: 5px;
}
