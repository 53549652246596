.settingsGeneralLabel {
    text-align: left !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* margin-right: 310px; */
    line-height: 30px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    width: 32%;
    padding-top: 5px;
    white-space: nowrap;
}

.PhoneInputCountry {
    width: 43px;
    height: 37px;
    padding: 6px;
    margin: 8px 4px;
    border-radius: 4px;
    border: 1px solid #d7deea;
    background: #ffffff;
    opacity: 1;
    box-shadow: 1px 2px 2px 0px #21242c1f inset;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}
.PhoneInput {
    width: 96%;
}
#inp_settings_general_phoneNumber {
    width: 80%;
    height: 36px;
    padding: 6px 12px;
    grid-gap: 4px;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #d7deea;
    background: #ffffff;
    opacity: 1;
    box-shadow: 1px 2px 2px 0px #21242c1f inset;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}
.label {
    margin-bottom: 0px !important;
}
.settingsContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}
.row.label.m-0.w-100.value {
    justify-content: center !important;
    display: flex;
}

#generalSettingsButton {
    width: 369px;
    height: 36px;
    padding: 6px 16px 6px 16px;
    gap: 8px;
    border-radius: 4px;
    background: #0b5cd7;
    color: #fff;
}

@media only screen and (max-width: 768px) {
    .settingsGeneralLabel {
        text-align: left !important;
    }
}

@media only screen and (max-width: 850px) {
    .settingsContainer {
        width: 95%;
    }
}

.settingInput {
    height: 3rem !important;
}
