.modal-textbox-container {
    border: 1px solid #b8b8b8;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    width: 350px;
    padding: 4px;
}
.modal-textbox {
    border: none;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    width: 100%;
}
.modal-button-primary {
    height: 36px;
    padding: 6px 16px 6px 16px;
    border-radius: 4px;
    background: #0b5cd7;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    color: #ffffff;
    border: 1px;
}
.modal-button-outlined {
    height: 36px;
    width: 84px;
    padding: 6px 16px 6px 16px;
    border-radius: 4px;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    color: black;
    border: 1px solid #000000;
}
.modal-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #404040;
}
.modal-error {
    color: red;
    font-size: 0.875em;
    font-weight: 400;
}
