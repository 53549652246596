.dashboard-icons {
    padding-top: 2px;
}

.measure-block {
    display: flex;
    justify-content: center;
    align-items: center;
}

.summary-blocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.summary-blocks span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    color: #000000;
}
.summary-blocks > .num {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 100% */
    padding-top: 10px;
    display: flex;
    align-items: center;

    color: #000000;
}
.displayName {
    white-space: nowrap; /* Prevent the text from wrapping to the next line */
    overflow: hidden; /* Hide any text that overflows the container */
    text-overflow: ellipsis; /* Display ellipsis (...) for overflowing text */
    max-width: 500px; /* Set a maximum width for the container */
    display: inline-block; /* Required for text-overflow to work */
    vertical-align: middle; /* Align text properly (optional) */
}

.displayName {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;

    align-items: center;
    letter-spacing: 0.15008px;
    margin-left: 15px;
    color: #212529;
}
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-paddingNone.MuiTableCell-sizeMedium.css-10dfkli-MuiTableCell-root {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #000000;
    padding-top: 12px;
}
tbody.MuiTableBody-root.css-apqrd9-MuiTableBody-root {
    font-family: "Montserrat";
    padding: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}
