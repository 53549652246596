.filterItem_row {
    background: #FFFFFF;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 4rem;
    display: flex;
    align-items: center;
}

.filterListContainer {
    background: #FFFFFF;
    box-shadow: 0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%), 0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 10px;
    height: 80%;
    padding-top: 1rem;
    overflow: auto;
}

.filterItem_title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #404040;
}

.filterCreateNewText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.intergrationFilterContainer {
    height: 90vh;
    padding: 1.7rem;
    margin-top: 1rem;
    padding-top: 0;

}

.integration_Title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #404040;
    text-transform: capitalize;
    margin-left: 1rem;
}