.checkMark {
    font-weight: bold;
}
/* AlertLog.module.css */
.alertBreadcrumb {
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.022em;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 8px;
}
.alertBreadcrumb span {
    color: #0a0a0a;
}

.alertBreadcrumb img {
    background: #0a0a0a;
    opacity: 0;
}
/* AlertLog.module.css */
#dropdownContainerRow1 {
    justify-content: flex-start;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0.8% 1.8% 0.5% 1.8%;
}

#dropdownContainerRow1 img {
    padding-right: 12px;
}
#dropdownContainerRow1 span {
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.022em;
    text-align: left;
}

.alertsHeader {
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.022em;
    text-align: left;
    background: #0a0a0a;
    display: flex;
    align-items: center;
    gap: 8px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.dash-flow.css-1dnl12b-MuiPaper-root {
    height: 28vh !important;
}
.tableContainer {
    padding-top: 0px;
    height: 90%;
    flex-basis: 90%;
}
@media screen and (min-width: 1280px) and (max-width: 1506px) {
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.dash-flow.css-1dnl12b-MuiPaper-root {
        height: 48vh !important;
    }
    div#rooot {
        height: 88vh;
    }
}

.stickyTableHead {
    background-color: #eaeef5;
}

.stickyTableCell {
    font-weight: 600;
    background-color: #eaeef5;
    padding-left: 20px;
}

/* .invisibleScroller {
    -ms-overflow-style: none; /* IE and Edge */
/* scrollbar-width: none; Firefox */
/* }  */
/* 
.invisibleScroller::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
/* } */

.MuiTableContainer-root {
    padding-top: 0px !important;
}
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.css-1ygcj2i-MuiTableCell-root {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.006em;
    text-align: left;
    /* display: flex; */
    padding-bottom: 32px;
}
#btn_vie {
    min-width: 6rem;
    margin: 10px 0px;
    margin-left: 1%;
    background: #0b5cd7 !important;
    height: 36px !important;
    padding: 6px 16px 6px 16px !important;
    grid-gap: 8px !important;
    grid-gap: 8px !important;
    gap: 8px !important;
    color: white;
    border-radius: 4px !important;
}
#btn_change {
    width: 157px;
    margin-left: 12px;
    height: 36px;
    padding: 6px 16px 6px 12px;
    grid-gap: 8px;
    gap: 8px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
}
.btn_class {
    width: 100%;
    height: 36px;
    padding: 6px 1px 6px 1px;
    grid-gap: 8px;
    gap: 8px;
    border-radius: 4px;
    margin: 8px 15px;
}
.date_range_class
    fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    top: -22px;
}
.date_range_class fieldset.MuiOutlinedInput-notchedOutline.css-igs3ac {
    top: -22px;
}

.cell_style {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: #4b5362;
}
.cell_bold_style {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: #4b5362;
}
.table td,
.table th {
    padding: 0.75rem;
    vertical-align: middle !important;
    border-top: 1px solid #dee2e6;
}
#table_alert {
    height: calc(100% - 100px);
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
    overflow-x: auto;
    width: 97%;
    height: 72vh;
    margin-left: 1.5%;
} /* Base styles for responsiveness */
div#rooot {
    height: 88vh;
}
#table_alert {
    height: calc(100% - 52px);
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
    height: clamp(25vh, 30%, 35vh); /* Flexible height based on screen size */
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.dash-flow.css-1dnl12b-MuiPaper-root {
    height: clamp(28vh, 6rem, 48vh) !important;
}

#alert_tab,
#mainCard {
    height: 72vh; /* Fixed height */
    overflow-x: auto;
    width: 97%;
    margin-left: 1.5%;
}

#instance_tab {
    margin-left: 0px;
    width: 100%;
    height: 73vh;
}

/* Media queries for broader ranges */
@media screen and (min-width: 1024px) {
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
        height: clamp(30vh, 35%, 40vh);
    }
    div#rooot {
        height: 88vh;
    }
}

@media screen and (min-width: 1280px) {
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
        height: clamp(35vh, 40%, 45vh);
    }
    div#rooot {
        height: 87vh;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.dash-flow.css-1dnl12b-MuiPaper-root {
        height: clamp(30vh, 36%, 40vh) !important;
    }
}

@media screen and (min-width: 1920px) {
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
        height: clamp(20vh, 30%, 35vh);
    }
    div#rooot {
        height: 87vh;
    }
}

@media screen and (min-width: 2700px) {
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
        height: 19vh;
    }
    div#rooot {
        height: 87vh;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.dash-flow.css-1dnl12b-MuiPaper-root {
        height: 28vh !important;
    }
}

/* Zoom and scale adaptability using max-width */
@media only screen and (max-width: 768px) {
    #table_alert,
    #alert_tab,
    #instance_tab,
    #mainCard {
        height: auto;
        margin-left: 0;
        width: 100%;
    }
}
