.formContainer {
    max-width: 32rem;
    /* max-height: 25rem; */
    /* overflow: auto; */
}
.MuiDialog-root {
    z-index: 99;
}
span.MuiButton-label,
.MuiButton-root,
#prevStep {
    text-transform: capitalize !important;
}
.heads {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
button#branchSelectionDisable {
    background-color: #f0f0f0;
    color: #6d6d6d;
}
.modal-container {
    width: 464px !important;
    height: 281px !important;
    top: 100px;
    left: 2268px;
    padding: 0px 24px !important;
    grid-gap: 32px;
    gap: 32px;
    border-radius: 4px 0 0 0;
    /* border: 1px solid #d7deea; */
    opacity: 1;
    background: #ffffff;
    box-shadow: none !important;
}
.set_cancel {
    width: 82px;
    height: 36px;
    padding: 6px 16px 6px 16px !important;
    gap: 8px;
    border-radius: 4px !important;
    border: 1px;
    background-color: none !important;

    border: 1px solid #d7deea !important;
}
.bw_btn {
    width: 83px;
    height: 36px;
    padding: 6px 16px;
    gap: 8px;
    border-radius: 4px !important;

    background: #0b5cd7 !important;
}
#overflowHide .MuiDialogContent-root {
    overflow: hidden;
}
#selectStorage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18rem !important;
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
    height: 40px;
    border-radius: 10px;
    box-shadow: none;
}
#changeStorage {
    width: 152px;
    height: 36px;
    background-color: #fff;
    gap: 8px;
    border-radius: 4px;
    border: 1px;

    font-weight: 600;
    border: 1px solid #d7deea;
}
