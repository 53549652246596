.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -16px;
}
.card-heading {
    /* height: 20%; */
    /* flex-basis: 15%; */
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 1rem;
    margin-bottom: 4px;
}
.card-description {
    /* height: 60%; */
    /* flex-basis: 70%; */
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 4px 0;
    width: 205px;
    height: 4.5rem;
}
.number-info {
    background: #fff;
    box-shadow: 1px 1px 20px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 24px;
    display: flex;
}
.number-info-icon {
    margin-right: 16px;
}
.number-info-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
.text {
    font-family: "Montserrat";
    font-size: 13px;
}
.number {
    font-size: 32;
    font-weight: 600;
}
