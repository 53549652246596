.rippleLogRow {
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 25%);
    border-radius: 0.6rem;
    min-height: 4rem;
    margin: 0;
    margin-bottom: 1rem;
    cursor: pointer;
    z-index: 1;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1rem;
}
.archiveItemRow,
.trashBinItemRow {
    cursor: default !important;
}
.clear {
    position: absolute;
    width: 79px;
    height: 18px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    letter-spacing: 0.1px;

    color: #1876d1;
}

.rippleLogRowOutline {
    background-color: #d5e4f3;
}

.saveBtn {
    width: fit-content;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    background: white;
    border-radius: 0px !important;
    border: none !important;
    margin: 0px !important;
    margin-left: 10px !important;
    height: 40px !important;
}

.rippleSaveButton {
    padding: 5px !important;
    width: -moz-fit-content;
    width: 8rem;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    background: white !important;
    border-radius: 0px !important;
    border: none !important;
    margin: 0px !important;
    margin-left: 10px !important;
    height: 40px !important;
    text-align: center;
    font-family: Montserrat !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    text-transform: none !important;
    white-space: pre !important;
}

.rippleSerachDiv {
    width: auto;
    flex-grow: 1;
}

.rippleTopbar {
    background: #ffffff;
    display: flex;
    align-items: center;
    height: fit-content;
    width: 60%;
    min-height: 3rem;
    justify-content: space-evenly;
}

.containerBody {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.react-flow__controls {
    bottom: 14%;
}
.rippleMainDiv {
    height: 88%;
    overflow-y: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    background: #ffffff;
    box-shadow: 0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 0.6rem;
}

/* .rippleMainDiv .MuiTablePagination-spacer {
    flex: none;
    margin-left: 10px;
}

.rippleMainDiv .MuiTablePagination-selectRoot {
    margin-left: 8px;
    margin-right: 65%!important;
}*/

.rippleMainDiv .MuiTablePagination-root {
    border: none;
    box-shadow: none !important;
    margin-top: 10px;
    position: sticky;
    bottom: 0;
    background: white !important;
}

.filterIcon {
    color: dodgerblue !important;
    font-size: 1.5rem !important;
    cursor: pointer;
}

.filterDropdown.MuiAccordionSummary-content.Mui-expanded {
    margin: 5px 0;
}

.filterDropdown.MuiPaper-elevation1 {
    box-shadow: none;
}

.filterDropdown.MuiAccordionSummary-root.Mui-expanded {
    min-height: 30px;
    background: white;
}

.spaceFilterDropdown.MuiAccordionDetails-root {
    padding: 0px;
}

.filterAccordian {
    max-height: 25vh;
    overflow: none;
}

.reactflowContainer {
    height: 92vh;
}

.rightPanelDrawer {
    height: 92vh;
}

.leftPanelRippleMainRow {
    height: 100%;
}

.leftCol {
    height: 92vh;
    overflow-y: auto;
}

.rightCol {
    height: 100%;
    overflow-y: auto;
}

.filterContainer {
    height: 70vh;
    /* overflow-y: scroll; */
}

.filterAccordian_label {
    font-weight: 500;
    margin: 0;
}

.filterAccordian_mainTitle {
    font-size: 1.2rem;
    margin: 0;
    font-weight: 400;
}

.chip .MuiChip-label {
    white-space: pre !important;
}

.rightPanelDrawer .tab-content {
    margin: 0 !important;
    height: 100% !important;
}

.displayBlock {
    display: block !important;
}

.rightPanelDrawer .MuiTimelineContent-root {
    padding: 6px 10px !important;
    height: 2.5rem !important;
    width: 4rem !important;
    position: relative;
    top: -0.5rem;
}

.rightPanelDrawer .MuiTimelineOppositeContent-root {
    padding: 6px 10px !important;
    height: 2.5rem !important;
    width: 4rem !important;
}

.rightPanelDrawer .MuiTimelineConnector-root {
    width: 1px !important;
    flex-grow: 1 !important;
    background-color: #000000 !important;
}

.rightPanelDrawer .MuiTimelineDot-defaultPrimary {
    color: #fff;
    border-color: transparent;
    background-color: #1b75bc;
}

.filterDropdownBtn {
    display: flex !important;
    align-items: center !important;
    padding: 5px !important;
    width: 90%;
    margin-bottom: 0.8rem;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    background: white !important;
    border-radius: 6px !important;
    border: aliceblue;

    height: 40px !important;
}
.newHeader {
    margin-left: 15px;
}
.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    margin-left: -6px;
    margin-top: 13px;
}
.spaceBetween {
    width: 100px;
    margin-right: 120px;
}
@media (min-width: 800px) and (max-width: 1200px) {
    .spaceBetween {
        width: 90px;
        margin-right: 40px;
    }
}
/* for aboove 110 px*/
@media (min-width: 1200px) and (max-width: 1500px) {
    .newHeader {
        margin-left: -15px;
    }
    .spaceBetween {
        width: 80px;
        margin-right: 100px;
    }
}
/* for below 90 px*/
@media (min-width: 1600px) and (max-width: 2052px) {
    .newHeader {
        margin-left: -10px;
    }
    .spaceBetween {
        width: 160px;
    }
}
/* for below 67 px*/

@media (min-width: 1200px) and (max-width: 2052px) {
    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        width: 230px;
        max-width: 1020px;
        max-height: 300px;
    }
    .flowBox {
        width: 400px;
    }
}
@media (min-width: 2052px) and (max-width: 2732px) {
    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        width: 275px;
        max-width: 1020px;
        max-height: 300px;
    }
    .flowBox {
        width: 400px;
    }
}
@media (min-width: 2733px) and (max-width: 4102px) {
    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        width: 370px;
        max-width: 1020px;
        max-height: 300px;
    }
    .flowBox {
        width: 400px;
    }
}
@media (min-width: 1800px) {
    #main_search {
        position: relative;
        left: 3rem;
    }
}
@media (min-width: 2100px) {
    #main_search {
        position: relative;
        left: 13rem;
    }
}
@media (min-width: 3000px) {
    #main_search {
        position: relative;
        left: 30rem;
    }
}
@media (min-width: 4103px) and (max-width: 5464px) {
    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        width: 548px;
        max-width: 1020px;
        max-height: 300px;
    }
    .flowBox {
        width: 400px;
    }
}

.dateFilterDropdownBtn {
    display: flex !important;
    align-items: center !important;
    width: 130px;
    /* margin-left: -64px; */
    /* margin-top: 30px; */
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    background: white !important;

    border: aliceblue;
    height: 35px !important;
}

div.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
}

/* 
path[marker-end="url(#react-flow__arrowclosed)"] {
    background-color: red;
} */
li.MuiTimelineItem-root.MuiTimelineItem-alignAlternate {
    height: 50px !important;
}

.react-flow__node {
    cursor: pointer !important;
}
/* .form-control {
    outline: none;
    border: none;
}
.form-control:focus {
    border: none !important;
    outline: none;
} */
.ripple-brand {
    margin-bottom: 2%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
}
input#rippleSearchTextBox {
    width: 100%;
    height: 55px;
    border-radius: 10px;
}
input#rippleSearchTextBox1 {
    width: 78%;
    height: 45px;
    border-radius: 10px;
}
.apply-button {
    background-color: #0798ea;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
}
.reset-button {
    background-color: white;
    color: #333;
    border: 1px solid #000;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 10px;
}
.search_text_ripple {
    position: relative;
    width: 574px;
    height: 54px;
    top: 4px;
    border-radius: 10px;
    left: 2px;
}
#name_ripple,
#hide_ripple {
    text-align: center !important;
    width: 92px !important;
    background: #fff;
    border: 1px #000000 solid !important;
}
#ripple-view-filters,
#ripple-ripple-log,
#name_ripple,
#hide_ripple {
    width: 144px;
    height: 41px;
    top: 375px;
    left: 568px;
    background: #fff;
    border: 1px #000000 solid !important;
    border-radius: 4px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
}
.search-cancel {
    cursor: pointer;
    position: absolute;
    margin: -61px -64px;
}
.search-btn1,
.search-btn {
    position: absolute;
    width: 63px;
    height: 54px;
    top: 4px;
    left: 553px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #0798ea;
}
.ripple_heading {
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}
#serachBarDiv {
    margin: auto;
    width: 60%;
    /* border: 3px solid #73AD21; */
    /* padding: 10px; */
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .search-btn1 {
        position: absolute;
        width: 63px;
        height: 54px;
        top: 4px;
        left: 287px !important;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: #0798ea;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1304px) {
    .search-btn1 {
        position: absolute;
        width: 63px;
        height: 54px;
        top: 4px;
        left: 407px !important;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: #0798ea;
    }
}
ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
    display: flex;
    flex-direction: column;
    padding: 13px;
}
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    padding: 10px;
    justify-content: start;
}
span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root {
    padding-left: 9px;
}
