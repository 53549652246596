.email-header {
    display: flex;
    width: 477px;
    justify-content: space-between;
    /* height: 30px; */
}

.head-text {
    /* width: 439px; */
    /* height: 28px; */
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.017em;
    text-align: left;
    color: #000000;
}

.email-inputs {
    width: 477px;
    /* height: 268px; */
    margin: 16px;
}

.input-label {
    width: 477px;
    /* height: 20px; */
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: #000000;
}
.input-label-search {
    width: 477px;
    /* height: 20px; */
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: #000000;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #1976d2 !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
    color: #0b5cd7 !important;
}
