.tab-button {
    width: 170px;
    height: 36px;
    padding: 6px 16px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #ffffff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
}

.tab-content-container {
    width: 100%;
    height: 100%;
    border: 1px solid #d7deea;
    border-radius: 4px;
    justify-content: center;
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
}

.main_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 5px 50px;
    height: calc(100% - 64px);
}

.tab-button.active {
    background-color: #ccc;
}

tr.MuiTableRow-root {
    height: 56px !important;
}

h1 {
    padding: 0 !important;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
}
.heading-setting {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: start;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    padding: 10px 0px;
    text-align: start;
}

button.tab-button {
    width: 50%;
}

.create-new-button {
    width: 139px;
    height: 36px;
    padding: 6px 16px 6px 12px;
    border-radius: 4px;
    background-color: #0b5cd7;
    border: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
}

.create-new-button:hover {
    background-color: #084ca0;
    /* darker shade of the background color on hover */
}

.wrapper-div {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.tabs-container {
    width: 40%;
    border-radius: 4px;
    border: 1px solid #9da5b4;
}

.emptyDiv span {
    font-family: "Montserrat";
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
}

.MuiTableContainer-root.invisibleScroller.spacelistTable {
    background-color: white;
    padding: 0px;
}

.emptyDiv {
    width: 100%;
    height: 65vh;
    display: flex;
    align-items: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

/* EmailAlertModal.css */

/* Add styles for smaller screens */
@media screen and (max-width: 768px) {
    .email-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .email-inputs {
        width: 100%;
    }

    .active {
        width: 100%;
    }

    .modal-buttons {
        width: 100%;
        justify-content: center;
    }
}

/* Additional media queries for even smaller screens if needed */
@media screen and (max-width: 480px) {
    /* Add additional styles as needed for smaller screens */
}
