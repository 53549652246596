.nested-modal-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.css-nw1vud-MuiButtonBase-root-MuiButton-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgba(25, 118, 210, 0.04);
    border: 1px solid #000 !important;
}

.input-rule-name {
    border: 1px solid #d7deea !important;
    padding: 10px;
    background: white;
    border-radius: 6px;
    border: 1px;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.006em;
    text-align: left;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    width: 240px;
}

.input-rule-label {
    margin: 0px;
    padding: 6px 12px 6px 12px;
    padding-top: 8px;
    padding-left: 0;
    border: 0;
    width: 155px;
    padding-right: 0px;
    height: 36px;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}

.input-rule-operator {
    margin: 0px;
    padding: 6px 12px 6px 12px;
    padding-top: 8px;
    padding-left: 0;
    border: 0;
    padding-right: 0px;
    height: 36px;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}

.css-1bcfi89-MuiTreeItem-content .MuiTreeItem-label {
    font-family: "Montserrat" !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0.15000000596046448px !important;
    text-align: left !important;
}

.labels {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
}

.move_rule {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
}

.rule-heading {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    width: 500px;
}

#demo-simple-select {
    padding: 7px 16px 7px 16px;
    border-radius: 4.32px;
    border: 1.08px solid #d7deea;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}

.conditions {
    display: flex;
    align-items: center;
    gap: 20px;
}

.tree-view {
    border: 1px solid #d7deea;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
    height: 220px;
    width: 97%;
    margin: 10px;
}

.action {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
}

.heading_move {
    padding: 0;
    height: 60px;
    margin-top: -30px;
    margin-left: -446px;
    text-align: left !important;
}

/* .modal-dialog.modal-md.modal-dialog-centered {
    max-width: 1000px;
    margin: 1.75rem auto;
} */
.row-heading {
    display: flex;
    align-items: center;
    height: 100px;
    /* padding: 16px 16px; */
    grid-gap: 10px;
    gap: 10px;
}

.rule-name {
    flex-grow: 1;
    /* Allow rule-name to grow and fill available space */
    display: flex;
    flex-direction: column;
    width: 76%;
    color: #21242c;
}

.rule-name > div {
    margin-bottom: 10px;
    /* Adjust as needed for space between divs within rule-name */
}

.recent-activity-log {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 300px;
    padding: 20px;
}
.row-activity {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    padding: 12px 16px;
    gap: 10px;
}
.rule-name-heading {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: #21242c;
}

.rule-details {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: #21242c;
}
.delete-btn {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 84px;
    padding: 18px 0px;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
    margin-left: 31px;
}

.edit-btn {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75px;
    /* Fixed width */
    height: 84px;
    /* Fixed height */
    padding: 18px 0px;
    /* Padding: top right bottom left */
    gap: 10px;
    /* Gap between elements */
    cursor: pointer;
}

/* Additional styling for the images within edit-btn and delete-btn if needed */
.edit-btn img,
.delete-btn img {
    max-width: 100%;
    /* Ensure image does not exceed container */
    max-height: 100%;
    /* Ensure image does not exceed container */
}
#moveRuleModal {
    height: 585px;
}
#moveRuleModal > #moveRuleConditions {
    height: 490px;
}
@media screen and (width: 1280px) {
    #moveRuleModal {
        height: 510px;
    }
    #moveRuleModal > #moveRuleConditions {
        height: 430px;
    }
}
@media screen and (min-width: 1023px) and (max-width: 1162px) {
    #moveRuleModal {
        height: 460px;
    }

    #moveRuleModal > #moveRuleConditions {
        height: 380px;
    }
}
@media screen and (min-width: 1163px) and (max-width: 1279px) {
    #moveRuleModal {
        height: 520px;
    }

    #moveRuleModal > #moveRuleConditions {
        height: 435px;
    }
}
@media screen and (min-width: 1422px) {
    #moveRuleModal {
        height: 630px;
    }

    #moveRuleModal > #moveRuleConditions {
        height: 550px;
    }
}
@media screen and (width: 1440px) {
    #moveRuleModal {
        height: 560px;
    }

    #moveRuleModal > #moveRuleConditions {
        height: 480px;
    }
}

@media screen and (width: 1920px) {
    #moveRuleModal {
        height: 780px;
    }

    #moveRuleModal > #moveRuleConditions {
        height: 695px;
    }
}
