.user-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.user-help {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.user-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}
.user-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    color: white;
    font-size: 10px;
    line-height: 12px;
}
/* .MuiPaper-root {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
} */
.MuiDivider-root {
    display: none;
}
