.dropDownValues{
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    letter-spacing: -0.006em !important;
    text-align: left !important;
    color: #000000 !important;
    font-style: normal !important;
}

div #sortBy{
    padding-left: 12px;
}
.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    background-color: #e0e0e0;
    border-radius: 10px;
    width: 100%;
}
.MuiFormControl-root.MuiTextField-root.css-i44wyl {
    background-color: #e0e0e0 !important;
    border-radius: 12px !important;
    width: 100% !important;
}