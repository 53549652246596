.Mui-focused .MuiCardHeader-root {
    background-color: #d0dae9;
}

ul#contactsSelection-listbox {
    margin: 0px 0px 0px 2px;
    border-radius: 10px;
}

.css-1tm81ay.focused {
    box-shadow: none !important;
    border: 1px solid #d9d9d9 !important;
}

.css-1ggoop8 span {
    background: #ebf2ff !important;
    border-radius: 99px !important;
    font-family: "Montserrat" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 17px;
    letter-spacing: 0em;
    height: 38px;
    width: 165px;
    padding: 10px 0px 6px 12px;
}

.focused .css-1ggoop8 {
    color: #0d1c2e !important;
    background-color: unset !important;
}

.css-1ggoop8 svg {
    position: absolute;
    margin-left: 162px;
    font-size: 25px;
    cursor: pointer;
    /* padding: 4px; */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* .Mui-focused {
    background-color: #d0dae9 !important;
} */

.user_chips {
    max-width: 6rem !important;
}
