.MuiCardHeader-action {
    margin-top: 0 !important;
    /* flex: 0 0 auto;
  align-self: flex-start;
  margin-right: -8px;
  max-height: 24px;
  line-height: 1;
  font-size: small; */
}

/* .MuiCardHeader-root {
  display: flex;
  padding: 12px !important;
  align-items: center;
}

.MuiCard-root:hover {
    background-color: #d0dae9 !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.MuiPaper-elevation1:hover {
    background-color: skyblue;
}

.MuiCardHeader-action {
    flex: 0 0 auto;
    align-self: flex-start;
    margin-top: -8px;
    margin-right: -8px;
    max-height: 24px;
    line-height: 1;
    font-size: small;
}

.readStatus {
    border-radius: 50%;
    position: absolute;
    height: 9px;
    width: 9px;
    background-color: green;
    margin-top: 30px;
    left: 16px;
    padding-right: 4px;
}

.MuiAvatar-root.MuiAvatar-circular.avatar.MuiAvatar-colorDefault {
  flex: 0 0 auto;
  margin-left: 16px;
  background-color: #1b75bc;
  height: 2.5rem;
  width: 2.5rem;
}

span.MuiTypography-root.MuiCardHeader-title.MuiTypography-body2.MuiTypography-displayBlock {
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding-top: 5px;
  /* padding-bottom: 5px; */
/* letter-spacing: 0em;
}

span.MuiTypography-root.MuiCardHeader-subheader.MuiTypography-body2.MuiTypography-colorTextSecondary.MuiTypography-displayBlock {
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  max-width: 12rem;
  text-overflow: ellipsis;
  white-space: pre;
} */
.avatar {
    background: #1b75bc !important;
    height: 24px;
    width: 24px;
    text-align: center;
    font-size: 1rem;
}

.contactBox {
    height: 4rem;
    padding-top: 0.8rem;
}

.contactTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: center;
    text-transform: capitalize;
    color: #0d1c2e;
}

.contactSubTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.625rem;
    color: #000000;
}

.timeStampDiv {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.625rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-transform: lowercase;
    height: 100%;
}

.chatTopBorder {
    border-top: 1px solid #ebebeb;
}

.chatBottomBorder {
    border-bottom: 1px solid #ebebeb;
}

#communicatorSelectedChatInfo .MuiPaper-rounded {
    border-radius: 0;
}
