.intgrationCard {
    background: #FFFFFF;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 5.25rem;
}

.integraionRightTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #0798EA;
}

.integraionTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #0798EA;

}

.integrationOutlookIcon {
    height: 51px;
    width: 58px;
}

.integrationGmailIcon {
    height: 40px;
    width: 51px;
}

.intgrationLeftCol {
    /* margin-right: 4rem; */
    margin-left: 1rem;
    min-width: 8rem;
}

.intgrationMiddleCol {
    display: flex;
    align-items: center;
}