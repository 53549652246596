p.MuiTypography-root.MuiDialogContentText-root.archive-message.MuiTypography-body1.MuiTypography-colorTextSecondary,
h2.MuiTypography-root.MuiTypography-h6 {
    font-family: "Montserrat";
}
.workImage {
    border-radius: 100px;
    display: flex;
    justify-content: center;
    /* width: 197.01px; */
    margin-left: auto;
    margin-right: auto;
    /* padding: 15px; */
    margin-top: 45px;
    height: 165.16px;
}

span.workText {
    display: flex;
    justify-content: center;
    font-family: "Montserrat";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 36px;
}

.subText {
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: 16px;
    letter-spacing: 0px;
    padding-top: 25px;
    text-align: center;
}

.workbtn {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 26px;
    margin-right: auto;
}

span.subText {
    display: flex;
    justify-content: center;
    padding-top: 25px;
}

.addSpace {
    margin-top: 15px;
    border-radius: 15px;
    flex-direction: column;
}

.addBtn {
    display: flex;
    padding-top: 26px;
    padding-bottom: 130px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.newSpace {
    display: flex;
    justify-content: center;
    /* width: 197.01px; */
    margin-left: auto;
    margin-right: auto;
    /* padding: 15px; */
    margin-top: 36px;
    margin-bottom: 40px;
}

.btnSpace {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 130px;
}

img#rip_icons {
    width: 18px;
    height: 18px;
    margin-bottom: 2px;
    margin-top: 8px;
}

#title {
    font-size: 12px;
    line-height: 15px;
    padding-left: 3px;
    padding-top: 1px;
}

span#rip_title,
span#rip_title_recur,
span#rip_title_share,
span#rip_title_delete {
    font-size: 12px;
    line-height: 15px;
    padding-left: 6px;
    padding-top: 1px;
}

div#btn_rippleLog_recure,
div#btn_rippleLog_share,
div#btn_rippleLog_delete {
    padding-left: 30px;
}

#icons {
    width: 18px;
    margin-top: 8px;
    height: 18px;
    margin-bottom: 6px;
}

.plusIcon {
    width: 0.8rem;
    height: 0.8rem;
}

li.MuiBreadcrumbs-li {
    padding-top: 5px;
}

.w-100.h-100.pl-4.pr-4.testClass {
    background-color: #fff;
    padding-top: 40px;
}

#spaceUploadeDocument .MuiPopover-paper {
    min-width: 80px !important;
    padding-top: 10px !important;
    /* border-radius: 10px; */
}

@media only screen and (min-width: 1500px) {
    .w-100.h-100.pl-4.pr-4.testClass {
        background-color: #fff;
        padding-top: 40px;
    }
}
.css-1aquho2-MuiTabs-indicator {
    position: absolute;
    height: 20px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #1976d2;
}
.MuiBox-root.css-1x1xx5k {
    max-width: none;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.css-zjf1nq-MuiPaper-root {
    padding: 0px;
}

p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root {
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 400;
}
img.MuiBox-root.css-1xgsf0v {
    height: 100%;
    max-width: none !important;
    padding-top: 20px;
}
@media only screen and (min-width: 1330px) {
    #btn_space_dropdown {
        display: none;
    }
}
@media only screen and (min-width: 800px) {
    #btn_space_dropdown_iPad2 {
        display: none;
    }
    #btn_space_dropdown_iPad {
        display: none;
    }
}
@media only screen and (min-width: 100px) {
    #btn_space_dropdown_none {
        display: none;
    }
}
@media only screen and (max-width: 1330px) {
    #div_table_download {
        display: none !important;
    }
    #div_table_move {
        display: none !important;
    }
    #div_table_share {
        display: none !important;
    }
    #dropdown_editspace {
        display: none !important;
    }
    #dropdown_addfile {
        display: none !important;
    }
    #dropdown_addnote {
        display: none !important;
    }
    #dropdown_archive {
        display: none !important;
    }
    #dropdown_trash {
        display: none !important;
    }
}
@media only screen and (max-width: 1024px) {
    .btn_space_dropdown #div_table_trash {
        display: none !important;
    }
    .btn_space_dropdown #div_table_archive {
        display: none !important;
    }
    #dropdown_archive {
        display: block !important;
    }
    #dropdown_trash {
        display: block !important;
    }
}
@media only screen and (max-width: 800px) {
    #btn_space_dropdown_iPad2 #dropdown_addnote {
        display: block !important;
    }
    #btn_space_dropdown #dropdown_addnote {
        display: block !important;
    }
    #btn_space_dropdown_iPad2 #dropdown_archive {
        display: none !important;
    }
    #btn_space_dropdown_iPad2 #dropdown_trash {
        display: none !important;
    }
    #btn_space_dropdown_iPad #dropdown_archive {
        display: block !important;
    }
    #btn_space_dropdown_iPad #dropdown_trash {
        display: block !important;
    }
    #btn_space_addnote {
        display: none !important;
    }
    .btn_space_dropdown_iPad #div_table_trash {
        display: none !important;
    }
    .btn_space_dropdown_iPad #div_table_archive {
        display: none !important;
    }
}
@media only screen and (max-width: 680px) {
    .btn_space_dropdown_iPad #div_table_rename {
        display: none !important;
    }
    #btn_space_dropdown_iPad #dropdown_editspace {
        display: block !important;
    }
    #btn_space_addfile {
        display: none !important;
    }
    #btn_space_dropdown_iPad2 #dropdown_addfile {
        display: block !important;
    }
    #btn_space_dropdown #dropdown_addfile {
        display: block !important;
    }
}
