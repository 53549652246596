.integrationContainer {
    /* overflow-y: scroll; */
    overflow-y: scroll;
    display: flex;
    flex-flow: column;
    height: 96vh;
    background-color: #fbfbfb;
}

.integrationTopContainer {
    background: #fbfbfb;
    display: block;
    height: 23rem;
    margin-bottom: 3rem;
}

.integrationTopContainer .carousel-inner,
.integrationTopContainer .carousel-item {
    height: 100%;
}

.integrationBottomContainer {
    background: #fbfbfb;
    height: 45%;
    padding-top: 1.5rem;
    margin-left: 0rem;
    margin-right: 0rem;
    margin-bottom: 3rem;
    /* box-shadow: 0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%), 0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 28px; */
}

.integrationBottomContainer .carousel-inner,
.integrationBottomContainer .carousel-item {
    height: 100%;
}

.integration_Title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.8rem;
    /* text-primary */
    color: #404040;
}

.integrationContainer_body {
    height: 20rem;
    background: #fff;
    box-shadow: 0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 1.75rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.integration_card {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 1.75rem;
    width: 90%;
    height: 95%;
    box-sizing: border-box;
}

.integration_card_row {
    width: 95%;
}

.integration_card_body_img {
    height: 2.5rem;
}

.integration_card_title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.938rem;
    /* text-primary */
    color: #404040;
}

.outlookModalTextbox {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
    padding-left: 1rem;
    border-radius: 10px;
    border: 1px solid #b8b8b8;
    outline: none;
    margin-top: 30px;
    height: 40px;
}

.outlookModalButton {
    height: 40px;
    margin-top: 45px;
    margin-left: 10px;
    background: #0798ea;
    color: white;
    width: 230px;
    border-radius: 10px;
}

.outlookModalLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    width: 210px;
    text-align: end;
    white-space: nowrap;
    margin-right: 10px;
}

.oModalTextbox {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
    padding-left: 1rem;
    border-radius: 10px;
    border: 1px solid #b8b8b8;
    outline: none;
    height: 40px;
}

.noBorderFocus:focus {
    outline: none !important;
}

.dropDownWrapper {
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ffffff;
    width: 100%;
    /* height: 3.5rem; */
}

.integration_text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #000000;
}

.Integration_icon {
    height: 52px;
    width: 52px;
}

.integrationEmptyContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
}
