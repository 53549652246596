.leftIcon {
    height: 2rem;
    width: auto;
    /* margin-right: 1rem; */
    padding-right: 0px;
}

.fontFamily {
    font-family: Montserrat;
    font-style: normal;
    color: #000000;
}

.rightIcon {
}

.feedLeftTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.feedTitle {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.3rem;
    /* or 105% */
    letter-spacing: 0.1px;
}

.feedCard {
    margin-top: 1rem !important;
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.feedCardBody {
    max-height: 8rem;
    overflow: auto;
    line-height: 2rem;
    padding-bottom: 1rem;
}

.feedContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 92%;
    width: 95%;
    /* margin-top: 20px; */
    /* overflow: scroll; */
}

.feedHeader {
    height: 4.5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.feedTitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 21px;
    /* or 105% */
    letter-spacing: 0.1px;
    color: #404040;
}
.feedTitle1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    /* or 105% */
    letter-spacing: 0.1px;
    color: #000000;
}

.feedContainerBody {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    padding-left: 0.5rem;
    padding-right: 1.5rem;
    padding-bottom: 2rem;
    margin-bottom: 1rem !important;
    display: flex;
    flex-flow: column;
    overflow: auto;
}

/* .feedContainerBody:hover {
    overflow: auto;
} */

.radiusControlBody {
    background: #fafafa;
    box-shadow: 0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 28px;
    width: 95% !important;
    overflow: hidden;
    margin-bottom: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    margin-left: 1rem;
}

.previewImg {
    height: 6rem;
    width: 100%;
    margin-top: 1rem;
}

.controlTowerImg {
    height: 100%;
    width: 100%;
}

.twitBodyText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */
    color: #000000;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.outlookBodyText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.3rem;
    /* or 150% */
    letter-spacing: 0.1px;
    color: #000000;
}
