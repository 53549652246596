body {
    font: 14px "Century Gothic", Futura, sans-serif;
    background-color: #fff;
    /* margin: 20px; */
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: rgba(0, 0, 0, 0.38) !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
}

.fullRow {
    width: 100%;
}

ol,
ul {
    padding-left: 30px;
}
#spanIconHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    margin-top: 9px;
    font-size: 10px;
    line-height: 12px;
    width: 90px;
}
#spanWorkspace {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
}
ul.no-bullets {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.mainContainerRow {
    margin-right: 0px !important;
}

.documentRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
}

.board-row {
    flex-direction: row;
    display: flex;
    margin-top: -1px;
}

.status {
    margin-bottom: 10px;
}

.square {
    background: #fff;
    border: 1px solid #999;
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    height: 34px;
    padding: 0;
    text-align: center;
    width: 34px;
    margin-left: 1px;
}

.kbd-navigation .square:focus {
    background: #ddd;
}
/* #item1_dashboard {
    margin: 10px -1% 4px 18px;
    width: 1347px;
} */
.game {
    display: flex;
    flex-direction: row;
}

.game-info {
    margin-left: 20px;
}

.element-invisible {
    position: absolute;
    width: 0px;
    height: 0px;
    left: -1000px;
}

.board-row *[aria-selected="true"] .square {
    outline: 1px solid black;
}

.board-row {
    height: 100px;
}

.iFrame {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
}

.radio {
    margin-right: 10px !important;
}

.radioInline {
    display: flex;
    justify-content: flex-start;
}

.div1 {
    width: 50%;
    margin-right: 40px;
}

.div2 {
    width: 50%;
}

.divGrid {
    display: flex;
    flex-direction: row;
}

.saveBtnDiv {
    display: flex;
    justify-content: center;
}

.saveBtn {
    width: 40%;
    font-size: 20px;
}

.offset-md-3 {
    width: 46% !important;
}

.fileUploadRow {
    width: 100% !important;
    margin: 0px !important;
}

.fileUploadCol {
    width: 90%;
}

h2 {
    margin-top: 5px;
}

.noPadding {
    padding: 0px;
}

.iconBtn {
    border-radius: 5px;
    background: whitesmoke;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconAlertModalBtn {
    height: 16px;
    width: 16px;
    cursor: pointer;
}

.home_alert_mediaIcon {
    color: rgb(133, 154, 165);
    font-size: 1rem;
    cursor: pointer;
}

.mediaIcon {
    height: 20px;
    width: 20px;
}

.roundedIconBtn {
    height: 45px;
    width: 46px;
    margin-top: 45px;
    margin-left: 10px;
    background: #2196f3;
    box-shadow: 0 1px 10px rgb(0 0 0 / 20%), 0 4px 5px rgb(0 0 0 / 12%),
        0 2px 4px rgb(0 0 0 / 14%);
    color: #fff;
    border-radius: 100px;
    font-size: 40px;
    border: none;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.folderIcon {
    font-size: 25px;
}

.doCenter {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.accordianTitle {
    display: flex;
    align-items: center;
    padding-left: 0px;
    outline: none;
}

.noOutline {
    outline: none;
}

.accordion > .card {
    overflow: hidden;
    border-bottom: none !important;
}
#main_box {
    width: 100%;
}
@media screen and (max-width: 2500px) {
    #main_box {
        width: 750%;
    }
}
@media screen and (max-width: 2000px) {
    #main_box {
        width: 500%;
    }
}
@media screen and (max-width: 1900px) {
    #main_box {
        width: 450%;
    }
}
@media screen and (max-width: 1800px) {
    #main_box {
        width: 400%;
    }
}
@media screen and (max-width: 1700px) {
    #main_box {
        width: 350%;
    }
}
@media screen and (max-width: 1600px) {
    #main_box {
        width: 300%;
    }
}
@media screen and (max-width: 1500px) {
    #main_box {
        width: 250%;
    }
}
@media screen and (max-width: 1400px) {
    #main_box {
        width: 200%;
    }
}
@media screen and (max-width: 1300px) {
    #main_box {
        width: 150%;
    }
}
@media screen and (max-width: 1250px) {
    #main_box {
        width: 100%;
    }
}
#search-instances1 {
    width: 100%;
}
#search-instances2 {
    width: 100%;
}
@media screen and (max-width: 2500px) {
    #search-instances1 {
        width: 1200px;
    }
    #search-instances2 {
        width: 1200px;
    }
}
@media screen and (max-width: 2200px) {
    #search-instances1 {
        width: 825px !important;
    }
    #search-instances2 {
        width: 825px !important;
    }
}
@media screen and (max-width: 2100px) {
    #search-instances1 {
        width: 750px;
    }
    #search-instances2 {
        width: 750px;
    }
}
@media screen and (max-width: 2000px) {
    #search-instances1 {
        width: 675px !important;
    }
    #search-instances2 {
        width: 675px !important;
    }
}

@media screen and (max-width: 1900px) {
    #search-instances1 {
        width: 600px !important;
    }
    #search-instances2 {
        width: 600px !important;
    }
}
@media screen and (max-width: 1800px) {
    #search-instances1 {
        width: 525px !important;
    }
    #search-instances2 {
        width: 525px !important;
    }
}
@media screen and (max-width: 1700px) {
    #search-instances1 {
        width: 450px !important;
    }
    #search-instances2 {
        width: 450px !important;
    }
}
@media screen and (max-width: 1600px) {
    #search-instances1 {
        width: 375px !important;
    }
    #search-instances2 {
        width: 375px !important;
    }
}
@media screen and (max-width: 1500px) {
    #search-instances1 {
        width: 300px !important;
    }
    #search-instances2 {
        width: 300px !important;
    }
}
@media screen and (max-width: 1440px) {
    #search-instances2 {
        width: 280px !important;
    }
}
@media screen and (max-width: 1400px) {
    #search-instances1 {
        width: 225px !important;
    }
    #search-instances2 {
        width: 225px !important;
    }
}
@media screen and (max-width: 1350px) {
    #search-instances1 {
        width: 200px !important;
    }
    #search-instances2 {
        width: 200px !important;
    }
}
@media screen and (max-width: 1280px) {
    #search-instances1 {
        width: 100px;
    }
    #search-instances2 {
        width: 152px !important;
    }
    #instance-search {
        width: 109px;
    }
}
.overflow-x {
    overflow-x: auto;
}

.flow-lib {
    margin: 10% 35%;
}
.accordianDownIcon {
    display: flex;
    align-items: center;
}

.alignCenterRow {
    height: 100%;
    align-items: center;
}

.container-fluid {
    padding: 0px !important;
}

.topNavbar {
    position: sticky;
    background: white;
    top: 0;
    z-index: 111 !important;
}

.subNavbar {
    position: sticky !important;
    top: 66px !important;
    z-index: 110;
    padding: 0px;
    /* margin-bottom: 10px; */
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    background: rgb(226 227 236);
}

.card-header:hover {
    background-color: rgba(0, 123, 255, 0.5) !important;
}

*[tabindex="0"] {
    outline: none;
}

.noOutline {
    outline: none !important;
}

a:hover {
    text-decoration: none !important;
}

a:active {
    color: whitesmoke !important;
}

li.active {
    color: whitesmoke !important;
}

.list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: rgba(0, 123, 255, 0.5);
}

.list-group-item.active {
    color: #000 !important;
    background-color: #d1efff !important;
}

/* .list-group {
  border-radius: 0px !important;
} */

.leftWorkspaceCol {
    padding-right: 5px;
    border-right: 7px solid lightgray;
}

.leftCollabCol {
    padding-right: 5px;
    padding-left: 5px;
    border-right: 7px solid lightgrey;
}

.userIcon {
    padding-right: 5px;
    padding-top: 3px;
    font-size: 20px;
    color: #007bff;
}
#graph {
    padding: 10px 1px 1px;

    position: relative;
    overflow-x: hidden;
}
.unityTitle {
    color: #93367c !important;
    font-size: 25px;
    font-style: inherit;
    font-weight: bold;
}

#basic-nav-dropdown {
    padding-left: 0px;
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    width: 40%;
}

.rightArrow {
    font-size: 18px;
    padding-right: 5px;
}

.rightBorderCol {
    height: 81vh;
    padding-right: 5px;
    border-right: 5px solid darkgray;
}

.dropdown-menu.show {
    z-index: 111111 !important;
}

.modal {
    /* z-index: 111111 !important; */
}

.modal-header {
    padding-top: 5px;
    padding-bottom: 5px;
}

.modal-footer {
    padding-top: 5px;
    padding-bottom: 5px;
}

.modal-body {
    padding-bottom: 10px !important;
}

.fieldset {
    display: block !important;
    margin-inline-start: 2px !important;
    margin-inline-end: 2px !important;
    padding-block-start: 0.35em !important;
    padding-inline-start: 0.75em !important;
    padding-inline-end: 0.75em !important;
    padding-block-end: 0.625em !important;
    min-inline-size: min-content !important;
    border-width: 1px !important;
    border-style: groove !important;
    border-color: lightgrey !important;
    border-image: initial !important;
}

.legend {
    width: fit-content !important;
    display: flex;
}

.accordianCardBody {
    height: 20rem;
    overflow: overlay;
}

.radioBtn {
    margin-left: 3px !important;
    margin-right: 2px !important;
}

.rightCol {
    height: 81vh !important;
    overflow-y: auto !important;
}

.tabIcon {
    color: black;
}

.tabText {
    font-size: 10px;
    text-align: center;
}

.tabCol {
    height: 81vh;
    border-right: 0.5px solid lightgray;
    box-shadow: 1px 1px 1px 1px whitesmoke;
}

.tabcontainerCol {
    height: 84.5vh;
    overflow-x: auto;
}

.tabColLeft {
    height: 81vh;
    border-left: 0.5px solid lightgray;
    box-shadow: 1px 1px 1px 1px whitesmoke;
}

.footer {
    border-top: 0.5px solid #d3d3d3 !important;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #007bff;
    color: #fff;
    text-align: center;
    padding: 0px;
    text-decoration: solid;
    margin: 0px;
}

.tabTreeCol {
    height: 81vh;
    overflow-y: auto;
    overflow-x: auto;
}

.flexCol {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0px;
}

.col-1,
.col-md-1 {
    max-width: 7.333333% !important;
}

.iconDiv {
    height: 30px;
    width: 30px;
    background: lightgray;
    margin-bottom: 2px;
    border-radius: 30px;
}

.unityCentralLogo {
    margin-left: 7px;
    height: 30px !important;
    margin-right: 5px;
}

.tabCard {
    display: flex;
    flex-direction: column;
}

.colorNeighborhood {
    color: #007bff;
    font: 14px "Century Gothic", Futura, sans-serif;
}

.colorCollaboration {
    color: #28a745;
    font: 14px "Century Gothic", Futura, sans-serif;
}

.colorWhiteboard {
    color: #dc3545;
    font: 14px "Century Gothic", Futura, sans-serif;
}

.colorCuboid {
    color: #ffcc00;
    font: 14px "Century Gothic", Futura, sans-serif;
}

.loadingTxt {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 45%;
}

.row.label {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.tab-content {
    /* padding-right: 155px; */
    background: #ffffff;
    box-shadow: 0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 0.6rem;
}

input.test {
    width: 70%;
}

input.test1 {
    border: none;
    border-bottom: #ccc 1px solid;
}

.nav-tabs .nav-link.active {
    background: dodgerblue;
    border-color: #dee2e6 #dee2e6 #fff;
    color: white;
}

.col-md-5.form-group.mb-5,
.col-md-10.form-group.md-5 {
    padding-left: 70px;
}
div#contained-modal-title-vcenter {
    text-overflow: ellipsis !important;
    white-space: inherit !important;
    overflow: hidden;
    margin-bottom: 0;
    line-height: 1.5;
}
.modal-Title-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* loader component classes start */

.modal-show {
    display: flex;
}

.modal-hide {
    display: none;
}

.modalcontent {
    border: none !important;
    background: transparent !important;
    align-items: center;
    width: fit-content;
    justify-content: center;
}

/* .modal-content {
  border: none!important;
  align-items: center;
  width: fit-content;
  justify-content: center;
} */

.modalBody {
    background: white;
    border-radius: 50px;
    box-shadow: 0px 0px 20px 20px #d3d3d347;
}

.MuiCircularProgress-indeterminate {
    width: 70px !important;
    height: 70px !important;
    animation: MuiCircularProgress-keyframes-circular-rotate 1.4s linear
        infinite;
}

.MuiCircularProgress-colorPrimary {
    color: dodgerblue;
}

/* string document  */

.stringPath {
    font-size: 16px;
    margin-bottom: 3px;
}

/* string document  */

/* loader component classes end */

.containerTitleBoarder {
    text-align: center;
    font-size: 20px;
    border-bottom-style: ridge;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

.headerBackground {
    background: dodgerblue;
    color: white;
}

.activeItemInSpaceTree {
    color: #fff;
    background-color: rgb(0, 123, 255);
}
#modalStyle {
    top: 357px;
    left: 960px;
    border-radius: 4px;
    padding: 24px;
    gap: 32px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    transform: translate(-50%, -50%);
    width: 324px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 10px;
}

.card-header:active {
    background: dodgerblue !important;
    color: white !important;
}

/* responsive Nav Bar */

.navbar-light .navbar-nav .nav-link {
    color: #007bff !important;
}

/* responsive Nav Bar */

/* widget start */

.leftSideWidget {
    border-bottom: 1px solid lightgrey;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 35px;
    display: flex;
    background: ghostwhite;
    align-items: center;
}

.minH .widgetTitle {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: inherit;
    font-size: 20px;
}

.widgetRow {
    margin-left: 10px;
    margin: 0px;
    padding: 0px;
}

.widgetBlock {
    border: 1px solid #d3d3d3;
    padding: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 10px 1px #789;
    margin-bottom: 9px;
    margin-top: 20px;
    margin-right: 20px;
}

.widgetBlockRed {
    border: 1px solid red;
    padding: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 10px 1px red;
    margin-bottom: 9px;
    margin-top: 20px;
    margin-right: 20px;
}

.widgetCardHeader {
    min-height: 35px;
}

.externalWidgetIconDiv {
    height: 50px;
    width: 50px;
    border-radius: 30px;
    background: #d3d3d3;
}

.externalWidgetDiv {
    min-height: 90px;
}

.externalWidgetIcon {
    font-size: 30px;
}

.externalWidgetText {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    text-align: center;
}

.newWidgeBlock {
    border: 5px dashed lightgray !important;
    box-shadow: none !important;
}

.outerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 70vh;
}

.innerDiv {
    border: 1px solid darkgray;
    width: 50%;
    border-radius: 13px;
    padding-top: 25px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.h-100 {
    height: 100% !important;
}

.h-81vh {
    height: 81vh;
}

.w-100 {
    width: 100% !important;
}

.mh-100 {
    min-height: 100px;
}

.mh-50 {
    min-height: 50px;
}

/* widget end */

/* responsive left tab */

@media only screen and (max-width: 768px) {
    .leftSideBarUl {
        display: flex;
        flex-direction: row;
        /* height: 50px; */
    }

    .tabTreeCol {
        height: fit-content;
        overflow-y: auto;
        overflow-x: auto;
        margin-left: 20px;
    }

    .tabCol,
    .tabColLeft {
        height: fit-content;
        box-shadow: 1px 1px 1px 1px #f5f5f5;
        display: flex;
        flex-direction: row;
        margin-left: 20px;
    }

    .rightCol {
        height: 81vh !important;
        margin-left: 20px;
        overflow-y: auto !important;
    }

    .flexCol {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        margin: 0;
    }
}

.documentRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
}

/* responsive left tab */

/* editor */

.dropableCanvas {
    height: 66vh;
    width: auto;
    overflow-y: auto;
    min-height: 100px;
    z-index: 121 !important;
    margin-right: 15px;
}

.editor {
    height: 70vh;
    overflow: auto;
}

.fileExtention {
    display: flex;
    align-items: center;
    padding-left: 0px;
    font-size: 18px;
}

.draggableNode {
    height: 20px;
    /* min-width: 60px; */
    position: absolute;
    z-index: 222323;
    background: #d1d1d1;
    color: #000;
    text-align: center;
    border-radius: 50%;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 0px 2px dodgerblue;
}

.popupMenu {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 150px;
    background-color: #fff;
    border: 1px solid black;
    z-index: 100000;
    box-shadow: 2px 2px #888;
    padding: 3px;
    padding-left: 15px;
    margin-top: 1px;
    height: 25px;
}

.popupMenu:hover {
    background-color: lightgray;
}

.manageSpaceContent {
    width: 100%;
    margin: 2px 20px;
}

.spaceCard {
    background: #ffffff;
    /* border: 3px solid #02C2FF; */
    box-sizing: border-box;
    box-shadow: 5px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 28px;
}

.viewFullScreenButton {
    background: #e3f2fd;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
}

.dashboardTab {
    height: 100px;
    border: 1px solid dodgerblue;
    box-sizing: border-box;
    box-shadow: 0px 0px 1px 1px lightgrey;
}

.noBorder {
    border: none;
}

.textColor {
    color: #212529 !important;
}

/* xmlViewer*/

#xmlViewerContainer {
    padding: 30px;
    width: 41.75rem;
    background: #fff;
    margin: auto;
    margin-bottom: 5px;
    overflow-y: auto;
}

#xmlViewer {
    scale: 1;
    transform-origin: 0% 0% 0px;
}

.xmlFileBody::-webkit-scrollbar,
.docViewContainer::-webkit-scrollbar,
#react-doc-viewer::-webkit-scrollbar,
#txt-renderer::-webkit-scrollbar {
    display: none;
}

/* fileViewer*/

#pg-viewer {
    height: 68vh !important;
}

.pg-viewer-wrapper::-webkit-scrollbar {
    display: none;
}

.pg-viewer-wrapper {
    width: 41.75rem !important;
    margin: auto !important;
}

.pg-viewer-wrapper .document-container {
    width: 41.75rem !important;
    min-height: 68vh !important;
}

.unsupported-message {
    min-height: 68vh !important;
}

#pg-viewer {
    scale: 1;
    transform-origin: 0% 0% 0px;
}

/* docViewer */
#react-doc-viewer {
    height: 68vh !important;
    padding: 30px;
    width: 60.75rem;
    background: #fff;
    margin: auto;
    margin-bottom: 5px;
}

#proxy-renderer {
    scale: 1;
    transform-origin: 0% 0% 0px;
}

#pdf-controls {
    display: none;
}

/* .modal.show .modal-dialog {
  transform: none;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
} */

/* dropdown */

.rippleTopbar {
    background: #ffffff;
    display: flex;
    align-items: center;
    height: 3rem;
    justify-content: flex-start;
}

.parentSearchDiv {
    padding: 0px;
    margin-right: 10px !important;
    min-height: 3rem;
    width: 30rem;
    background: #fff;
    border-radius: 10px;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 48%);
}

.flowDashBoard input,
.flowDashBoard input:focus {
    border: none !important;
}

.serachBarDiv .serachInputBox {
    /* border: none !important; */
    padding: 0px !important;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
}

.serachBarDiv .serachInputBox:focus {
    color: #495057;
    background-color: #fff !important;
    border-color: #ced4da !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.searchBtn {
    background: #00d1ff;
    border-radius: 99px;
    letter-spacing: 0.44px;
    color: #ffffff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    border: none;
}

.searchBtn:disabled {
    background: #00d1ff !important;
    border-radius: 99px !important;
    letter-spacing: 0.44px !important;
    color: #ffffff !important;
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    border: none !important;
}

.rippleButtonTxt {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #000000;
}

.nameRippleBtn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 5px !important;
    width: fit-content;
    min-width: 8rem;
    max-width: 9rem;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    background: white !important;
    border-radius: 6px !important;
    border: none !important;
    margin: 0px !important;
    margin-left: 10px !important;
    height: 40px !important;
}

.rippleDD {
    max-width: 9rem;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    background: white !important;
    border-radius: 6px !important;
    border: none !important;
    margin: 0px !important;
    margin-left: 10px !important;
}

.rippleDDFilterView {
    max-width: 21rem !important;
    width: 285px !important;
    margin: 15px 7px !important;
}

.dropdownIcon {
    font-size: 15px !important;
    font-weight: 600 !important;
    position: relative !important;
    margin-left: 10px !important;
    text-transform: none !important;
}

.dropdownBtn {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    text-transform: none;
}

.renderTop {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px; /* Padding for better spacing */
}
.rightPanelDrawer {
    flex: 0 0 25%;
    max-width: 25%;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .rightPanelDrawer {
        flex: 0 0 45%;
        max-width: 41%;
    }
    .searchBtn {
        left: 287px !important;
    }
    .pl-0.doCenter.pr-0.search_text_ripple {
        width: 400px !important;
    }
    input#rippleSearchTextBox1 {
        width: 45% !important;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1304px) {
    .rightPanelDrawer {
        flex: 0 0 35%;
        max-width: 31%;
    }
    .pl-0.doCenter.pr-0.search_text_ripple {
        width: 400px !important;
    }
    input#rippleSearchTextBox1 {
        width: 72% !important;
    }
    .searchBtn {
        left: 407px !important;
    }
}
.chipList {
    list-style: none;
    padding: 0px;
    display: flex;
    justify-content: left;
    align-items: center;
    /* width: 90%; */
    margin: auto 10%;

    flex-wrap: wrap;
}

.MuiChip-clickable {
    cursor: default !important;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
.chiplistDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.chip {
    color: #2264d1 !important;
    background: #ebf2ff !important;
    border-radius: 99px !important;
    height: 22px !important;
    margin-right: 5px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    max-width: 6rem;
}
.addInstanceChip {
    color: #0b5cd7 !important;
    border: 1px solid #0b5cd7 !important;

    background: white !important;
    border-radius: 99px !important;
    height: 24px !important;
    margin-right: 5px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    max-width: 6rem;
}

.chipIcon {
    color: #2264d1 !important;
    background: #ebf2ff !important;
}
.solidChipIcon {
    color: white !important;
    background-color: #0b5cd7;
    border-radius: 50%;
    width: 14px !important;
    height: 14px !important;
}

/* ripple Log  */
.flexAlignCenter {
    display: flex;
    align-items: center;
}

.rippleLogRow {
    background: #ffffff;
    box-shadow: 5px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 28px;
    margin: 10px;
    min-height: 65px;
}

.headerRow {
    margin: 10px;
    min-height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.headerLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.15px;
    margin: 0px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: 14px;
    font-family: "Montserrat";
    justify-content: start;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Montserrat" !important;
    border-radius: 10px !important;
}
ul.MuiList-root.MuiList-padding.MuiMenu-list.css-r8u8y9 {
    font-size: 14px !important;
    font-family: "Montserrat" !important;
}
.css-3z0a3c-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #000 !important;
    font-weight: 500 !important;
}
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-1km1ehz {
    display: flex !important;
    justify-content: flex-start;
    padding: 10px !important;
}
.MuiListItemText-root.css-1tsvksn {
    padding-left: 5px !important;
}
.menu-custom {
    min-width: 200px;
    padding: 6px 0px !important;
}
.menu-item-custom {
    display: block !important;
    padding: 6px 16px !important;
    justify-content: start !important;
}
.empty-container-heading {
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 500;
    line-height: 34.13px;
    text-align: left;
    color: #000;
}
.empty-container-subheading {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: left;
    color: #000;
}
.ruleNameDoc {
    width: 784px;
    height: 100px;
    gap: 0px;
    opacity: 0px;
}
.ruleNameDoc p {
    margin: 0;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}
.alert-dashboard {
    display: flex;
    align-items: center;
    gap: 12px;
}
.btn_wrap {
    display: flex;
    justify-content: flex-end;
}
.btn-dashboard {
    border: 1px solid #000000;
    background: none;
    width: 94px;
    height: 35px;
    padding: 4px 10px 4px 8px;
    gap: 8px;
    border-radius: 4px;
}
.upload-title {
    font-size: 12px;
    font-weight: 700;
    font-family: Montserrat;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0px 11px;
}

.ripple-text {
    display: inline-block;
    max-width: 85px; /* Adjust the width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}
.textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    white-space: pre;
}
textarea#txt_Invite_messageBody {
    border: 1px solid #e5e1e1;
}
.css-1nbw9tx,
.css-1km1ehz {
    font-family: "Montserrat" !important;
}
.css-1ald77x.Mui-error {
    color: rgba(0, 0, 0, 0.38) !important;
}
.css-1chpzqh,
.css-16gvrju {
    font-family: "Montserrat" !important;
}
#flowId {
    text-align: center !important;
    /* position: absolute;
    left: 52%; */
    width: 850px !important;
    transform: translateX(10%);
}
.css-levciy-MuiTablePagination-displayedRows {
    font-family: "Montserrat" !important;
}
.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    font-family: "Montserrat" !important;
}
.css-pdct74-MuiTablePagination-selectLabel {
    font-family: "Montserrat" !important;
}
.rippleLogTableHead {
    /* min-width: 60px !important; */
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
}

/* .modal-content .MuiTablePagination-selectRoot {
    margin-left: 8px;
    margin-right: 55%;
} */

.shareModalTextBox {
    height: 40px;
    background: #ffffff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
    width: 70% !important;
    margin: 10px;
}

.modalButton {
    height: 40px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 10px;
    background: #2196f3;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 12%),
        0px 2px 4px rgb(0 0 0 / 14%);
    border-radius: 100px;
    color: white;
    border: none;
    min-width: 30%;
}

.modalTitle {
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

/* login page */

.userAlert {
    width: 120px;
    height: 120px;
    border: none;
    background: #ffffff;
    box-shadow: 5px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%),
        0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 28px;
}

.UserAlertIcon {
    height: 50px;
    margin-bottom: 5px;
}

.redBorder {
    border: 1px solid red !important;
}

.marginTopTen {
    margin-top: 10px;
}

.fontBlack {
    color: #000000 !important;
}

/* table view for space handler */

.nameCell {
    overflow: hidden;
    max-width: 4rem;
    text-overflow: ellipsis;
    white-space: pre;
}

.demo-2 {
    display: flex;
    flex-direction: row;
}

#fileBreadcrumb li {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.errorMsg {
    font-size: 12px;
    color: red;
    margin-left: 10px;
    margin-right: 10px;
}

.dropdown {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 250px !important;
    height: 35px;
}

.ctDropDownButton {
    display: flex;
    align-items: center;
    padding: 5px;
    width: fit-content;
    min-width: 250px !important;
    max-width: 11rem;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    background: white;
    border-radius: 0px;
    border: none;
    margin: 0px;
    margin-left: 10px;
    height: 40px;
}

.ctDocumentList {
    margin-bottom: "10px";
    margin-top: "90px";
}

.defaultScroller {
    overflow-y: auto !important;
    overflow-x: hidden;
    height: 100vh;
}

.defaultScroller::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
}

.defaultScroller::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.defaultScroller::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #c1c1c1;
}

.invisibleScroller {
    overflow: hidden;
}

.invisibleScroller:hover {
    overflow-y: auto;
}

.invisibleScroller::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.invisibleScroller::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
}

.invisibleScroller::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #c1c1c1;
}

.workspaceList {
    white-space: pre;
}

.PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    border: 0;
    outline: none;
}

.modalCloseIconRow {
    display: flex;
    justify-content: end;
    align-items: center;
}

.modalCloseIcon {
    font-size: 1.8rem !important;
}

.MuiBreadcrumbs-li {
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileNameLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 27px;
    color: #404040;
    margin: 0px;
    margin-bottom: 0.8rem;
    /* margin-left: 0.2rem; */
}

.xmlViewer {
    height: 80vh;
    min-height: 25rem;
    overflow: auto;
}

#txt-renderer {
    white-space: pre-wrap;
    /* overflow: hidden; */
}

#txt-renderer:hover {
    overflow-x: auto;
}

#txt-renderer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

#txt-renderer::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
}

#txt-renderer::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #c1c1c1;
}

.stickyHeader {
    position: sticky;
    top: 0;
    background: white;
}

.fontFamily {
    font-family: Montserrat;
    font-style: normal;
    color: #000000;
}
img#image-main {
    width: 23px;
    height: 27px;
    margin-left: 3px;
}

#proxy-renderer {
    overflow: hidden;
}

#proxy-renderer:hover {
    overflow-y: auto;
}

#proxy-renderer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

#proxy-renderer::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
}
.Header_dashboard {
    /* width: 1136px !important; */
    /* height: 110px !important; */
    padding: 8px 0px 0px 0px !important;
    gap: 16px !important;
    /* opacity: 0px; */
}
#chipContainer ul {
    margin: 0 !important;
}
#DC_dashboard_SearchBox {
    box-shadow: 1px 2px 2px 0px #21242c1f inset !important;
    /* margin-left: 20px !important; */
    top: 12px;
    height: 36px !important;
    grid-gap: 4px;
    grid-gap: 4px;
    gap: 4px;
    border-radius: 4px;
    /* opacity: 0px; */
    margin-right: 2px !important;
    border: 1px solid #d7deea;
    padding-left: 10px;
}

/* @media screen and (max-width: 1400px) {
    #DC_dashboard_SearchBox {
        width: 208px !important;
    }
} */
.new-alert {
    display: flex;
    grid-gap: 16px !important;
    grid-gap: 16px !important;
    gap: 16px !important;
    margin: 16px 18px;
    /* opacity: 0px !important; */
}
em {
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal !important;
    font-weight: 500;
    line-height: 21.58px;
    letter-spacing: -0.006em;
    text-align: left;
    padding-right: 5px;
}
/* #btn_more_compare {
    width: 57px !important;
    background: #fff !important;
    height: 40px !important;
    padding: 6px 16px 6px 16px !important;
    grid-gap: 8px !important;
    grid-gap: 8px !important;
    gap: 8px !important;
    border-radius: 98px !important;
    opacity: 0px;
    margin: 8px 10px !important;
} */

.dashboard_col .dashboard_cards {
    width: 20%;
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    /* margin-right: 20px; */
    height: 84px;
    /* flex-basis: 50%; */
}
.compareCards.card-body {
    width: 630px;
    height: 94px;
    padding: 16px 0px 0px 0px;
    gap: 0px;
    border-radius: 4px 4px 0px 0px;
    border: 0px 0px 1px 0px;
    justify-content: space-between;
    /* opacity: 0px; */
    margin-bottom: 0;
}
.statCards.card-body {
    width: 322px;
    height: 92px;
    padding: 8px 0px 0px 0px;
    grid-gap: 0px;
    grid-gap: 0px;
    gap: 0px;
    border-radius: 4px 4px 0px 0px;
    border: 0px 0px 1px 0px;
    justify-content: space-between;
    /* opacity: 0px; */
    margin-bottom: 0;
}
.row-stats {
    display: flex !important;
    height: 52px !important;
    justify-content: space-around;
    /* opacity: 0px !important; */
}

.dashboard_cards {
    background: #fff;
    /* background: #ffffff; */
    box-shadow: 5px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    /* margin-bottom: 1px; */
    border-radius: 4px;
    border: 1px solid #eaeef5;
    box-shadow: 0px 4px 10px 0px #00000014, 0px 1px 4px 0px #0000000a;
}
#btn_apply {
    width: 75px;
    height: 36px;
    padding: 6px 16px 6px 16px;
    gap: 8px;
    grid-gap: 8px !important;
    gap: 8px !important;
    border-radius: 4px !important;
    /* opacity: 0px; */
    border: none;
    background-color: #0b5cd7;
    color: #fff;
}

#btn_rest {
    margin-left: 9px;
    width: 146px !important;
    background: #fff !important;
    height: 36px !important;
    padding: 6px 16px 6px 16px !important;
    grid-gap: 8px !important;
    gap: 8px !important;
    border-radius: 4px !important;
    /* opacity: 0px; */
    border: 1px solid #d7deea;
}
#btn_flow {
    width: 160px !important;
    background: #fff !important;
    height: 36px !important;
    padding: 6px 16px -1px 16px !important;
    grid-gap: 8px !important;
    gap: 8px !important;
    border-radius: 4px !important;
    /* opacity: 0px; */
}
.css-140r5r1-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    font-family: Montserrat;
    height: 44px !important;
}
#btn_more {
    width: 110px;
    height: 36px;
    padding: 6px 16px 6px 12px;
    gap: 8px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
}
#header_btn_more {
    width: 91px;
    height: 36px;
    padding: 6px 16px 6px 12px;
    gap: 8px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
}
#btn_export {
    width: 102px;
    height: 36px;
    padding: 6px 16px 6px 12px;
    gap: 8px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
}
#btn_attach_flow {
    height: 36px;
    padding: 6px 16px 6px 12px;
    gap: 8px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    width: 18%;
}
#btn_view_result {
    min-width: 10rem;
    margin: 10px 0px;
    margin-left: 1%;
    background: #0b5cd7 !important;
    height: 36px !important;
    padding: 6px 16px 6px 16px !important;
    grid-gap: 8px !important;
    gap: 8px !important;
    color: white;
    border-radius: 4px !important;
    /* opacity: 0px; */
}
/* .css-12izeqw-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 150px;
} */

.dashboard_cards h5,
h5.card-title.mb-0 {
    left: 872.99px;
    top: 557px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000;
}
.dashboard_items1 {
    height: 366px !important;
    padding: 8px 11px 24px 24px !important;
    grid-gap: 16px !important;
    grid-gap: 16px !important;
    gap: 16px !important;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px #00000014, 0px 1px 4px 0px #0000000a;
    border: 1px solid #eaeef5;

    border-radius: 4px;
    flex-direction: column;
    margin: 0px 18px;
}
/* #card_dashboard {
    margin-top: -190px;
    margin-left: 29px;
    width: 1350px;
} */

.dashboard_chart-items {
    width: 560px;
    display: flex;
    flex-basis: 50%;
    margin: 4px;
    padding: 24px 0px 0px 0px;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    box-shadow: 0px 4px 10px 0px #00000014, 0px 1px 4px 0px #0000000a;
    border: 1px solid #eaeef5;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    /* opacity: 0px; */
}
thead.MuiTableHead-root.css-188radp-MuiTableHead-root {
    background: #eaeef5 !important;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}
.alert-list {
    width: 98%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.dashboard_items {
    flex: 3;
    height: 500px;
    grid-gap: 16px !important;
    grid-gap: 16px !important;
    gap: 16px !important;
    box-shadow: 0px 4px 10px 0px #00000014, 0px 1px 4px 0px #0000000a;
    border-radius: 4px !important;
    /* opacity: 0px !important; */
}
.counts {
    width: 1681px !important;
    height: 473px !important;
    grid-gap: 16px !important;
    gap: 16px !important;
    border-radius: 4px !important;
    /* opacity: 0px !important; */
}
.dashboard_graph {
    overflow-y: auto;
    border-radius: 10px;
    flex-basis: 90%;
    min-height: 320px;
    width: auto;
}
#createNew {
    width: 170px;
    height: 36px;
    padding: 6px 16px 6px 16px;
    gap: 8px;
    border-radius: 4px;
    text-align: center;
    background-color: #0b5cd7;
}
@media (min-width: 1280px) {
    #createNew {
        width: 200px;
    }
}
#btn_view {
    min-width: 10rem;
    margin: 2px 0px;
    margin-left: 1%;
    background: #0b5cd7 !important;
    height: 37px !important;
    padding: 6px 16px 6px 16px !important;
    grid-gap: 8px !important;
    grid-gap: 8px !important;
    gap: 8px !important;
    color: white;
    border-radius: 4px !important;
}
.css-xn82ks-MuiTable-root,
thead.MuiTableHead-root.css-16ez718 {
    width: 1782px !important;
    height: 60px !important;
    grid-gap: 0px;
    gap: 0px;
    /* opacity: 0px; */
}
thead.MuiTableHead-root.css-16ez718 {
    min-width: 170px;
    font-weight: 600;
    background-color: rgb(234, 238, 245);
}
.css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    line-height: 24px !important;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    background: #d7deea !important;
}
#search_instance {
    width: 383px;
    height: 36px;
    grid-gap: 1px;
    gap: 1px;
    /* opacity: 0px; */
}
#search_create {
    /* width: 383px; */
    height: 36px;
    grid-gap: 1px;
    gap: 1px;
    flex-basis: 50%;
    justify-content: flex-end;
    /* opacity: 0px; */
}
button#Instance,
button#Branches {
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    padding: 11px !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.MuiToggleButtonGroup-root.css-bghv3y-MuiToggleButtonGroup-root {
    width: 182px !important;
    height: 36px !important;
    grid-gap: 0px;
    grid-gap: 0px;
    gap: 0px;
    border-radius: 4px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    /* opacity: 0px; */
}
.cardContainer {
    flex: 2;
    border-radius: 4px !important;
    background: none;
    box-shadow: none;
    display: flex;
    box-shadow: 0px 4px 10px 0px #00000014, 0px 1px 4px 0px #0000000a;
    border: 1px solid #eaeef5;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
    height: 500px !important;
}
#proxy-renderer::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #c1c1c1;
}
.instanceChip {
    border: 1px solid #ccc;
    border-radius: 3px;
}
input#familyTagsValue {
    border: none !important;
}
.rippleSerachDiv.instanceText {
    width: 420px;
    border-radius: 12px;
}
.node-details .tab-content {
    height: 80% !important;
    margin-top: 10px;
    margin-left: -23px;
}
.TopHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.centerLogo {
    margin-left: 170px;
}
.grey-head {
    color: #9da5b4;
}
.readOnly {
    cursor: not-allowed !important;
}
.dashboard_col1.dashboard_cards1 {
    background: #fff;
    box-shadow: 5px 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04),
        0 0 1px rgba(0, 0, 0, 0.04);
    margin-bottom: 1px;
    border-radius: 10px;
}
