.header-part {

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30px;
    gap: 8px;
    margin-bottom: 8px;
}

.body-container {
    width: 100%;
    max-height: 19.688rem;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: #424242;
    margin-bottom: 8px;


}

.heading-text {
    width: 314px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.017em;
    text-align: left;
    color: #000000;
}

.bottom-part {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 52px;
    padding: 16px 0 0 0;
    gap: 8px;
}

.btn-cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 83px;
    padding: 6px 16px;
    border-radius: 4px;
    border: 1px solid #D7DEEA;
    gap: 8px;
    background: #FFFFFF;
    color: #000000;
    text-transform: capitalize;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-align: left;
}

.btn-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 92px;
    padding: 6px 16px;
    border-radius: 4px;
    gap: 8px;
    text-transform: capitalize;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-align: left;
}