.searchDiv {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgb(0 0 0 / 48%), 0px 0px 4px rgb(0 0 0 / 12%);
    border-radius: 10px;
    width: 90%;
}

.userNameDiv {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px;
    justify-content: center;
}

.chatMassegeInputDiv {
    display: flex;
    width: 90%;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 10px;
}

.chatHeaderLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #0D1C2E;
}

.topBorder {
    margin-top: 20px !important;
    border-top: 1.5px solid #EBEBEB;
}

.borderBottom {
    border-bottom: 1.5px solid #EBEBEB;
}

.rightBorder {
    border-right: 1.5px solid #EBEBEB;
}

.notificationDotShow {
    display: block;
}

.notificationDotHide {
    display: none;
}

.boldLabel {
    font-weight: bold;
}

.userNameLabel {
    font-family: Montserrat;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: left;
    text-transform: capitalize;
    color: #0D1C2E;
    cursor: pointer;
}

.companyLabel {
    font-family: Montserrat;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: left;
    color: #2A8BF2;
}

.profileImage {
    height: 40px;
    width: 40px;
}

.defualtProfileImage {
    font-size: 3rem !important;
    height: 80px !important;
    width: 80px !important;
}

.avtarDiv {
    height: 40px;
    width: 40px;
    background: #1e90ff;
}

.attachIcon {
    height: 15px;
    width: 15px;
}


/* chat msg body start */

.leftMsg {
    text-align: left;
    background: #F3F3F3;
    border-radius: 0px 10px 10px 10px;
    width: fit-content;
    max-width: 80%;
    padding: 10px;
    margin-left: 10px;
}

.timeLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-transform: lowercase;
    color: rgba(112, 124, 151, 0.7);
    margin-left: 10px;
    margin-right: 10px;
}

.rightMsg {
    text-align: left;
    background: #0798EA;
    border: 1px solid rgba(112, 124, 151, 0.25);
    width: fit-content;
    max-width: 80%;
    box-sizing: border-box;
    box-shadow: 10px 10px 25px rgb(112 124 151 / 5%), 15px 15px 35px rgb(112 124 151 / 5%);
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px 10px 0px 10px;
}

.rightMsgDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px !important;
}

.leftMsgDiv {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px !important;
}

.timeDivRight {
    display: flex;
    justify-content: flex-end;
}

.timeDivLeft {
    display: flex;
    justify-content: flex-start;
}

.selectedBackground {
    background: #F5F9FF;
}

.msgBoxSticky {
    position: sticky;
    bottom: 0;
    background: white;
    margin-top: 10px;
}

.stickyChatHeader {
    position: sticky;
    top: 0;
    background: white;
}

.chatBadge {
    color: white;
    border-radius: 25px;
    padding: 5px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-chat {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px;
    justify-content: center;
}

.welcome-chat .chat-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-chat h1 {
    font-size: 20px;
    font-weight: bold;
    margin: 14px 0px 50px;
}

img.chatIcon {
    display: flex;
    align-items: center;
    margin: 30px auto;
}

.welcome-chat p {
    margin-top: 0;
    padding: 0px 64px;
    text-align: center;
    margin-bottom: 1rem;
}

/* Communicator new css changes */