.timeline_view_container {
    height: 100%;
    width: 100%;
    margin-top: 1rem;
    padding-bottom: 58px;
}

.timeline_paper {
    height: 100%;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
}
.timeline_paper:hover {
    border: 2px solid #2a8bf2;
}
.no_selection {
    user-select: none;
    /* standard syntax */
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
}
