/* Modal container */
.modal-container {
    width: 464px;
    height: 332px;
    top: 100px;
    left: 1729px;
    padding: 24px;
    gap: 32px;
    border-radius: 4px 0 0 0;

    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1),
        0px 1px 1px 0px rgba(0, 0, 0, 0.05);
}

/* Customize Storage heading */
.customize-storage-heading {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000000;
    margin-bottom: 24px;
}

/* ControlledTreeView styles */

.tree-view {
    width: 416px;
    height: 144px;
    padding: 20px 6px;
    grid-gap: 0px;
    gap: 0px;
    background: #fff;
    overflow-y: auto;
    opacity: 1;
}

/* Loader styles
.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */
