#shareDropdownContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.stepContainerBox {
    height: 50vh;
    min-width: 45vh;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

#shareDropdownContainer .dropdown_Btn {
    width: 100% !important;
    height: 2.5rem !important;
    box-shadow: none !important;
    border: 1px solid #b8b8b8 !important;
    border-radius: 10px !important;
    margin-bottom: 1rem !important;
}

.stepFooter {
    display: flex;
    justify-content: flex-end;
}

#shareDropdownContainer .dropdown_Btn button {
    height: 2.5rem !important;
}