.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root:hover {
    background: none !important;
    color: #044dba !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root:focus {
    background: none !important;
} /* checkBox.css */

/* Remove focus outline for all checkboxes */
.MuiCheckbox-root:focus {
    outline: none;
}

/* Add red border to the adjacent sibling of the focused checkbox */
.MuiCheckbox-root:focus + .MuiCheckbox-root::after {
    content: "";
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    border: 2px solid red;
    border-radius: 4px;
    pointer-events: none;
}

.custom-checkbox:focus {
    outline: none;
    border: 3px solid #0b5cd733;
    width: 20px;
    border-radius: 4px;
    border: 3px;
    color: #044dba !important;
    height: 20px;
    outline: none;
    border: 3px solid #0b5cd733;
}
