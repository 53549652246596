.stepContainer {
    background: #eef9ff;
    border: 3px solid #009fff;
    border-radius: 10px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px;
}
.contentRow {
    display: flex;
    justify-content: space-between;
}

#title {
    font-size: 15px;
    line-height: 20px;
    /* padding-left: 12px; */
}
.content {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: -0.022em;
    color: #333333;
}
