.userInfoContainer {
}

.listItemTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 2.125rem;
    font-size: 0.875rem;
    letter-spacing: 0.1px;
    color: #000000;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    cursor: pointer;
}

.listItemSubTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 1.125rem;
    letter-spacing: 0.1px;
    color: #000000;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.bottomBorder {
    border-bottom: 1px solid #a3a3a3;
}

.arrow_icon {
    font-size: 1.6rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
}
.invite {
    background-color: rgb(39, 41, 44);
    border-color: rgb(39, 41, 44);
}
.invite:hover {
    background-color: rgb(39, 41, 44);
    border-color: rgb(39, 41, 44);
}
.invite:active {
    background-color: rgb(39, 41, 44) !important;
    border-color: rgb(39, 41, 44) !important;
}
.invite:focus {
    background-color: rgb(39, 41, 44) !important;
    border-color: rgb(39, 41, 44) !important;
}
#help-dropdown {
    background-color: rgb(39, 41, 44);
    border-color: rgb(39, 41, 44);
}
/* .dropdown-menu.show{background-color:rgb(39, 41, 44);} */
.help {
    height: 118px;
    background-color: white;
    position: absolute;
    color: black;
    width: 211px;
    border-radius: 4px;
    padding: 12px;
    line-height: 32px;
    list-style-type: none;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    z-index: 1002;
    margin: 75px 0px 0px 0px;
    list-style-type: none;
}

.dropdown {
    width: 125px !important;
}
