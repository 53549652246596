* {
    font-family: "Montserrat";
}
#mainDashboard {
    height: 89vh;
    overflow-y: scroll;
}

div#mainDashboard .invisibleScroller {
    margin-left: "1200px";
}
.dashboard-doc {
    font-family: "Montserrat";
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    padding: 16px;
    line-height: 22px;
}
.headerSortDown:after,
.headerSortUp:after {
    content: " ";
    position: relative;
    left: 10px;
    border: 8px solid transparent;
}
.statCards {
    height: 80px;
    margin-bottom: 12px;
    padding: 3px 4px 4px 16px;
}

.headerSortDown:after {
    top: 14px;
    border-top-color: silver;
}

.headerSortUp:after {
    bottom: 11px;
    border-bottom-color: silver;
}

.headerSortDown,
.headerSortUp {
    padding-right: 10px;
}

.main_dashboard {
    display: flex;
    height: auto;
    background-color: #fff;
}
.subTitles {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
}
.titles {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: black;
}

.dashboard_cards h5 {
    left: 872.99px;
    top: 557px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000;
}
.dashboard_cards span,
span.h2.mb-0 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 20.5062px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.265375px;
    color: #383874;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 3.61875px;
}

.Header_dashboard-compare {
    flex-basis: 100%;
    /* overflow-y: auto; */
    background: #ffffff;
    box-shadow: 1px 1px 20px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    align-items: center;
    height: auto;
    min-height: 125px;
    width: 98.5%;
    margin: 1% 13% 1% 18px;
}
.css-13xfq8m-MuiTabPanel-root {
    padding: 12px !important;
}

.timeline_dashboard {
    flex-basis: 100%;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px #00000014, 0px 1px 4px 0px #0000000a;
    border: 1px solid #eaeef5;
    border-radius: 4px;
    align-items: center;
    /* height: auto; */
    height: 21.35rem;
    margin-top: 12px;
    /* padding-right: 8px !important;
    padding-left: 0px !important; */
    width: 97%;
    margin: 1% 18px 0px 18px;
}

.dashboard_alert {
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}

.dashboard_items {
    display: flex;
    flex-basis: 50%;
    width: 49%;
    max-height: 515px;
    background: #ffffff;
    border: 1px solid #eaeef5;
    border-radius: 4px;
    box-shadow: 0px 4px 10px 0px #00000014, 0px 1px 4px 0px #0000000a;
    flex-direction: column;
}
.measure-block {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #000000;
}

.dashboard_col.col {
    padding: 20px 16px 16px 10px;
    overflow-y: auto;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px;
    border-radius: 10px;
    flex-basis: 100%;
    height: auto;
}

.data_dashboard {
    width: 101%;
    border-radius: 10px;
    margin-bottom: 12px;
    margin-left: 10px;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px;
}

.dashboard_chart {
    margin: 16px 16px;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}

.dashboard_graph {
    /* padding: 20px 16px 16px 10px;
    margin-bottom: 20px; */
    overflow-y: auto;
    /* background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px; */
    /* border-radius: 10px; */
    flex-basis: 90%;
    min-height: 300px;
    width: auto;
}

.dashboardAlert {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;
    padding: 20px 5px;
    margin: 5px 14px;
    height: 100px;
    width: 100%;
}

.alertIcon img {
    width: 35px;
    height: 35px;
}

.listHeader {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #404040;
}

.listBody {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: #000000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}

.nocounts {
    display: flex;
    flex-basis: 47%;
    width: 100%;
    height: 400px;
    max-height: 400px;
    border-radius: 10px;
    /* margin-top: 12px; */
    background-color: #ffffff;
    box-shadow: 1px 1px 20px 4px rgba(0, 0, 0, 0.1);
    margin: 1% 1% 4% 1%;
}

.row {
    flex-wrap: nowrap !important;
}
.counts {
    /* height: 400px; */
    display: flex;
    flex-basis: 58%;
    /* width: 49%; */
    border-radius: 10px;
    margin-top: 9px;
}

.dashboard_col {
    flex-basis: 20%;
    width: 20%;
}

.dashboard_cards {
    background: #fff;
    background: #ffffff;
    box-shadow: 5px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    margin-bottom: 1px;
    border-radius: 4px;
    box-shadow: 0px 4px 10px 0px #00000014, 0px 1px 4px 0px #0000000a;
}
.dashboards_cards {
    background: #fff;
    background: #ffffff;
    box-shadow: none;
    /* width: 385px;
    margin: auto; */
    align-content: center;
    /* display: flex; */
    /* justify-content: center; */
    border-bottom: 1px solid #c7c7c7;
    margin-bottom: 1px;
}
.cardContainer {
    background: none;
    box-shadow: none;
    flex-basis: 30%;
    border-radius: 4px !important;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
    height: 525px;
    box-shadow: 0px 4px 10px 0px #00000014, 0px 1px 4px 0px #0000000a;
    border: 1px solid #eaeef5;
}

/* .dashboard_cards .card-body {
    flex: 1 1 auto;
    padding: 1.5rem 1rem;
} */
.table_dashboard {
    border: none;
}

.table_dashboard .tableInside {
    border: 1px solid #fff;
    padding-bottom: 20px !important;
}

.table_dashboard th,
td {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;

    color: #000000;
}

.tableData {
    flex-basis: 55%;
    /* width: 55%; */
    margin-left: 75px;
}

.dashboard_col.tableData.col {
    height: 400px;
}
@media (max-width: 1097px) {
    .dashboard_cards h5 {
        font-size: 12px !important;
    }
    .dashboard_cards span {
        font-size: 18px !important;
    }
}
@media (max-width: 960px) {
    .dashboard_cards h5 {
        font-size: 10px !important;
    }
    .dashboard_cards span {
        font-size: 17px !important;
    }
}
/* 200 */
@media (max-width: 768px) {
    .dashboard_cards h5 {
        font-size: 8px !important;
    }
    .dashboard_cards span {
        font-size: 15px !important;
    }
}

.dashboard_cards h5 {
    left: 872.99px;
    /* padding-bottom: 13px; */
    top: 557px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.dashboard_cards span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 20.5062px;
    line-height: 27px;
    /* identical to box height, or 129% */

    display: flex;
    align-items: center;
    letter-spacing: -0.265375px;

    /* Night Blue */

    color: #383874;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 3.61875px;
}

.dashboard_ratio {
    padding-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

span.text-error.mr-2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    /* or 143% */

    display: flex;
    align-items: center;
    letter-spacing: 0.265375px;

    /* Electric Green */

    color: #00b929;
    padding: "12px";

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 0px;
}

i.fa.fa-arrow-up {
    padding-right: 8px;
}

.dashboard_firstCol {
    margin-top: 15px;
}

.timelineTitleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 0.8rem;
}

.documentViewToggel,
.documentTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    color: #000000;
}

@media only screen and (min-width: 1000px) and (max-width: 1280px) {
    #DC_TimeLine {
        margin-left: 20% !important;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1425px) {
    #DC_TimeLine {
        margin-left: 17% !important;
    }
}

@media only screen and (min-width: 1426px) and (max-width: 1700px) {
    #DC_TimeLine {
        margin-left: 15% !important;
    }
}

@media only screen and (min-width: 1701px) and (max-width: 1850px) {
    #DC_TimeLine {
        margin-left: 14% !important;
    }
}

@media only screen and (min-width: 1851px) and (max-width: 1920px) {
    #DC_TimeLine {
        margin-left: 12% !important;
    }
    .timeline_dashboard {
        width: 98%;
    }
}
@media screen and (max-width: 1200px) {
    /*Write your css here*/
    .statCards {
        height: 60px;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px) {
    #DC_TimeLine {
        margin-left: 9% !important;
    }
    .statCards {
        height: 70px;
    }
}

@media only screen and (min-width: 2561px) {
    #DC_TimeLine {
        margin-left: 6% !important;
    }
    .statCards {
        height: 70px;
    }
}
