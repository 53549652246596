.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: rgb(64, 64, 64);
    width: 430px;
    margin-top: -32px;
}
.member-actions {
    display: flex;
    align-items: center;
    padding: 12px 6px;
    padding-left: 0px;
    gap: 12px;
}
.member-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 6px;
}
.member-column1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: "14px";
}
.member-column2 {
    display: flex;
    align-items: center;
}

.members-table-heading {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 14px 16px 14px 16px;
    background: #eaeef5;
    font-family: "Montserrat";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}
.devider {
    width: 100%;
    border-bottom: 1px solid #eaeef5;
}
.members-table-row {
    display: flex;
    padding: 12px 16px 12px 16px;
    align-items: center;
}
.members-name {
    width: 40%;
    font-weight: 600;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 10px;
}
.members-share {
    width: 20%;
    font-weight: 500;
    text-align: center;
}
.members-receive {
    width: 20%;
    font-weight: 500;
    text-align: center;
}
.members-download {
    width: 20%;
    font-weight: 500;
    text-align: center;
}
