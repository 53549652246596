.container_directory {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 12%;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    box-shadow: 0px 0px 1px 0px #00000040;
}
h1 {
    font-family: "Montserrat";
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
}
.invite_modal_title {
    font-family: "Montserrat" !important;
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 21px;
}
#btn_remove {
    width: 93px;
    height: 41px;
    border: 1px #000 solid;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: left;
}
.container-remove {
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.table-head.css-1bigob2 {
    font-family: "Montserrat" !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-1hw9j7s {
    background-color: #0798ea;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    height: 40px;
    border-radius: 10px;
    background-color: #0798ea;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.contain-btn.css-sghohy-MuiButtonBase-root-MuiButton-root {
    background-color: #0798ea;
}
.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-pwxzbm {
    box-shadow: 0px 0px 3px 0px #00000040;
}
#contain-btn {
    width: 210px !important;
    font-family: "Montserrat";
    font-size: 16px !important;
    text-decoration: none;
    font-weight: 400;

    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    background-color: #0798ea !important;
    height: 50px !important;
    border-radius: 10px;
    color: #fff;
}
.container-create {
    border: 1px #ccc solid;
    margin-bottom: 21px;
    width: 451px;
    border-radius: 12px;
    padding: 8px;
    height: 42px;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.table-head.css-1ygcj2i-MuiTableCell-root {
    font-family: "Montserrat" !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    height: 40px;
    border-radius: 10px;
    background-color: #0798ea;
}
.search-table {
    display: flex;
    justify-content: space-between;
    padding: 27px 0px;
}
.table-title {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.5142599940299988px;
    text-align: left;
    padding: 20px;
}
.search-box {
    box-shadow: 0px 0px 4px 0px #0000001f;
    box-shadow: 0px 0px 1px 0px #0000007a;
    width: 362px;
    height: 37px;
    border: #adadad solid 1px;
    border-radius: 10px;
}
