button#cancelButton {
    width: auto;
    margin-top: 0px !important;
    height: 36px !important;
    text-align: center;
    font-weight: 500;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;

}