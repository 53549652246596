.invite_div_box {
    padding: 0px;
    min-height: 3rem;
    width: 90%;
    background: #fff;
    box-shadow: 0px 0px 1px 0px;
    align-items: center;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
}
.invite-search {
    border-radius: 1px;
}
.invite_msg_box {
    width: 90% !important;
    margin-top: 2rem;
    border: none;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    font-family: "Montserrat";
    padding: 10px;
}

.userSerachInputBox {
    outline: none;
    display: block;
    width: 100%;
    height: 35px;
    padding: 0.475rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: none;
    height: 35px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .userSerachInputBox:active, .userSerachInputBox:focus {
    border: 1px solid lightgray;
    border-radius: 10px;
} */

.invite_modal_title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875;
    /* or 150% */
    letter-spacing: 0.15px;
    color: #000000;
}
