* {
    font-family: "Montserrat";
}
.flow_icon {
    width: 24px;
    height: 28.8px;
    padding: 2.4px, 0px, 2.4px, 0px;
}
.css-bghv3y-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped,
.css-7rg7fp .MuiToggleButtonGroup-firstButton,
.css-7rg7fp .MuiToggleButtonGroup-middleButton {
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.docChainContainer {
    height: 70vh;
    width: auto;
    display: flex;
    flex-direction: column;
    margin: 0px;
}
.flow_title {
    width: 500px;
    padding: 5px 12px 0px 45px;
    font-family: "Montserrat";
    font-size: 32px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.022em;
    text-align: left;
}

.heading-generic {
    padding-top: "19px" !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */

    /* text-primary */

    color: #404040;
}

.MuiToggleButtonGroup-root.css-1ya7z0e {
    margin-right: 20px !important;
}

.headContainer {
    display: flex;
    height: 10%;
    flex-basis: 10%;
    margin-top: 10px;
}

.headings {
    flex-basis: 50%;
    width: 50%;
    justify-content: space-between;
    margin-left: 25px;
    margin-right: 75px;
}

.d-flex {
    justify-content: space-between;
    display: flex;
    padding: 37px;
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 99;
}

.editMode {
    flex-basis: 100% !important;
    width: 100% !important;
}

.mainContainer {
    display: flex;
    height: 100%;
    flex-basis: 90%;
    margin-top: 2rem;
}

.genSteps {
    flex-basis: 50%;
    padding: 36px;
    width: 50%;
    background: white;
    margin: 0px 25px 25px 25px;
    border-radius: 10px;
    /* box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px; */
}

.geneSteps {
    padding-bottom: 35px;
    flex-basis: 50%;
    width: 50%;
    background: white;
    margin: 0px 25px 25px 25px;
    border-radius: 10px;
    /* box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px; */
}

.MuiPopover-paper {
    min-width: 120px !important;
    padding: 0px !important;
}

.addStep {
    position: absolute;
    top: 12px;
    border: 1px solid black;
    border-radius: 10px;
    height: 24px;
    width: 30px;
    background: white;
    padding: 4px;
    padding-top: 2px;
    cursor: pointer;
}

#addIcon {
    height: 15px;
    width: 15px;
    margin: 0px;
}

.button#demo-controlled-open-select {
    background-color: #fff;
    border: none;
    color: #000;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
    /* filter: drop-shadow(30px 10px 4px #4444dd); */
    padding: 0px 37px;
    box-shadow: 1;
}

#demo-controlled-open-select {
    background-color: #fff;
    color: #000;
    width: auto;
    margin-top: -9px;
    border: none;
    padding: 13px 21px;
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px;
}

.generic {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;

    /* text-primary */

    color: #404040;
}

.css-1xhj18k {
    margin-left: 15% !important;
}

.backFlow {
    display: flex;
    align-items: center;
    margin-left: 34px;
    justify-content: center;
}

.flowHeadingContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 200px; */
}

.branchView {
    display: flex;
    justify-content: center;
    align-items: center;
}

.library {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0px 2px 0px 5px;
    cursor: pointer;
}

.editGeneric {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* margin-top: 12px; */
    cursor: pointer;
    padding: 6px 12px 8px 12px;
    border-radius: 4px;
}

.genericArea {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.newInstance {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0px 25px;
    cursor: pointer;
}

#div_branch_text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0px 25px 0px 25px;
    cursor: pointer;
}

@media (min-width: 1001px) and (max-width: 1268px) {
    .library {
        margin: 0;
    }

    .editGeneric {
        width: 120px;
    }

    .genericArea {
        width: 450px;
        margin: 36px auto;
    }

    .newInstance {
        margin: 0px;
        width: 128px;
    }

    #div_branch_text {
        margin: 0px;
        padding: 0px;
    }
}

.manage-alerts {
    width: 500px;
    height: 100%;
    padding: 24px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background: white;
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px;
    display: flex;
    flex-direction: column;
}
.manage-alerts-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
}
.manage-alerts-heading-text {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.017em;
    text-align: left;
    color: #000;
}
.alert-cards {
    height: calc(100% - 82px);
}
.alert-card {
    padding: 8px 16px 8px 16px;
    gap: 0px;
    display: flex;
    justify-content: space-between;
}
.alert-card .text {
    width: 45%;
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}
.alert-card .table {
    width: 55%;
}
.alert-row {
    padding: 0px 16px 0px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.alert-row-text {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}
.manage-alert-action {
    padding: 16px 0px 0px 0px;
    gap: 12px;
    display: flex;
    justify-content: flex-end;
    height: 52px;
}
