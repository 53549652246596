.App {
    font-family: sans-serif;
    text-align: center;
}
/* testdropdown.css */

.select-search-container {
    width: 300px;
    margin: 8px auto;
    font-family: Arial, sans-serif;
}
.dropdownShare-container {
    width: 100%;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: border-color 0.3s ease;
    height: 36px;
}
.dropdown-container {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: border-color 0.3s ease;
    height: 36px;
}

.dropdown-container:hover {
    border-color: black;
}
.dropdownShare-container:hover {
    border-color: black;
}

.dropdown-open {
    border-color: #0b5cd7;
}

.select-search-value {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-icon {
    padding: 12px;
    transition: transform 0.3s ease;
}

.icon-rotate-up {
    transform: rotate(180deg);
}

.icon-rotate-down {
    transform: rotate(0deg);
}
.dropdown-list1 {
    /* top: 100%; */
    left: auto;
    width: inherit;
    border: 1px solid #ccc;
    position: absolute;
    border-top: none;
    background: #fff;
    z-index: 1000;
    max-height: 227px;
    overflow-y: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-list {
    top: 100%;
    left: auto;
    width: inherit;
    border: 1px solid #ccc;
    position: absolute;
    border-top: none;
    background: #fff;
    z-index: 1000;
    max-height: 227px;
    overflow-y: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.dropdown-list_wk {
    top: 100%;
    left: auto;
    width: inherit;
    border: 1px solid #ccc;
    border-top: none;
    background: #fff;
    z-index: 1000;
    max-height: 227px;
    overflow-y: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-input {
    padding: 10px;
    background: #efeded;
}
.dropdown-input input {
    background: #efeded;
}

.select-all-option {
    display: flex;
    align-items: center;
    padding: 10px;
}

.select-search-options {
    list-style: none;
    margin: 0;
    padding: 0;
}

.select-search-options li {
    padding: 10px;

    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select-search-options li:hover {
    background-color: #f0f0f0;
}

.select-search-options input[type="checkbox"] {
    margin-right: 8px;
}
/* style.css */
.disabled {
    pointer-events: none;
    opacity: 0.6;
}
