.containerClass {
    display: flex;
    height: 100%;
}
.containerColumns {
    flex-basis: 33.33%;
    display: flex;
    flex-direction: column;
    box-shadow: rgb(0 0 0 / 25%) 0px 1px 10px;
    border-radius: 10px;
}
.columnHeading {
    flex-basis: 10%;
    padding: 8px;
    margin: auto;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;

    /* text-primary */

    color: #404040;
}
.columnData {
    flex-basis: 90%;
    overflow-y: auto;
}
.rowContainer {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c4c4c4;
    cursor: pointer;
    margin-left: 24px;
    margin-right: 24px;
    padding-left: 18px;
    padding-right: 18px;
}
/* .columnData :hover {
    background-color: grey;
} */
.rowItem {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: #1b75bc;
    height: 42px;
    margin-top: 8px;
}
