.default .modal-content {
    position: relative;
    background-color: #edf8ff;
}
h1 {
    height: 31.53px;
    padding: 53px 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    color: #000000;
}
h6 {
    height: 125px;
    padding: 40px 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center !important;
    color: #000000;
}
button.test {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0798ea;
    margin-bottom: 100px;
    margin-top: 20px;
    border-radius: 10px;
    width: 240px;

    height: 64px;
}
