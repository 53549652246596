.defualtContainer {
    margin-top: 5rem;
}

.defualt_title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;

    color: #000000;
}

.defualt_subTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    /* or 114% */

    text-align: center;

    color: #000000;
}
.InfoHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom-style: none;
}

.MuiTablePagination-select, .MuiTypography-root, .MuiTablePagination-menuItem {
    font-family: "Montserrat" !important;
}
