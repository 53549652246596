/* recure modal */

.weekDays-selector input {
    display: none !important;
}

.weekDays-selector input[type="checkbox"] + label {
    display: inline-block;
    border-radius: 10px;
    background: transparent;
    height: 30px;
    width: 50px;
    margin-right: 3px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0px;
}

/* .weekDays-selector input[type=checkbox]:checked+label {
    background: #2196F3;
    color: #ffffff;
    border-radius: 1rem;
} */

.selectedDate {
    background: #2196f3 !important;
    color: #ffffff !important;
    border-radius: 1rem !important;
}
.selectedDate1 {
    background: #b8b8b8 !important;
    color: #fff !important;
    border-radius: 1rem !important;
}

.react-datepicker__input-container input {
    border: none;
}

ul.react-datepicker__time-list {
    overflow: hidden;
    cursor: pointer;
}

ul.react-datepicker__time-list:hover {
    overflow-y: auto;
}

ul.react-datepicker__time-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

ul.react-datepicker__time-list::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
}

ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #c1c1c1;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    list-style: none;
    overflow: hidden;
    margin: 0;
    padding-left: 10px;
    overflow-y: auto;
    flex-basis: 40%;
    height: 50%;
    margin-top: 1px;
    height: 10rem !important;
    overflow-y: auto;
    padding: 10px;
    background: white;
    width: 8rem;

    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.box_btn {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    background: white !important;
    border: none !important;
}

.recureModal_count_select {
    display: flex !important;
    align-items: center !important;
    padding: 5px !important;
    width: -moz-fit-content;
    width: 5rem;
    /* min-width: 5rem; */
    /* max-width: 11rem; */
    margin: 0px !important;
    margin-left: 10px !important;
    height: 40px !important;
    border-radius: 8px;
}

.recureModal_week_select {
    display: flex !important;
    align-items: center !important;
    padding: 5px !important;
    width: -moz-fit-content;
    width: 9rem;
    margin: 0px !important;
    margin-left: 10px !important;
    height: 40px !important;
    border-radius: 8px;
}

.recureModal_time_select {
    display: flex !important;
    align-items: center !important;
    padding: 5px !important;
    width: -moz-fit-content;
    width: 9rem;
    margin: 0px !important;
    margin-left: 10px !important;
    height: 40px !important;
    border-radius: 8px;
}

.recureTimeRow {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.react-datepicker-wrapper {
    margin-top: 12px;
}
.react-datepicker__tab-loop {
    z-index: 999;
}

.react-datepicker.react-datepicker--time-only {
    margin-top: 13px;
    margin-left: -90px;
    padding-left: 15px;
}
.rippleLog_label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: #000000;
}

.recure_On_label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #000000;
}
.ripple-heading {
    position: absolute;
    width: 394px;
    height: 55px;
    padding-left: 7px;
    left: 25px;
    top: 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.44px;
    color: #000000;
}
.ripple-subheading {
    position: absolute;
    width: 369px;
    height: 24px;
    left: 33px;
    top: 48px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    display: flex;
    align-items: center;
    letter-spacing: 0.44px;

    color: #000000;
}
