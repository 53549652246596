.chkTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}

.chkDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#DC_dashboard_SearchBox .serachInputBox {
    padding: 0px !important;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    width: 98%;
}
/* div#dropdownContainerRow {
    display: flex;
    justify-content: center !important;
}
.newHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
} */

#DC_dashboard_SearchBox .serachInputBox:focus {
    color: #495057;
    border: 1px solid;
    background-color: #fff !important;
    border-color: #ced4da !important;
    outline: 0 !important;
    box-shadow: none !important;
}
/* .searchBar {
    min-height: "2.5rem";
    width: 100%;
    border: 0px;
    box-shadow: none;
    margin-right: "20px";
}
@media (min-width: 800px) and (max-width: 1400px) {
    div#dropdownContainerRow {
        justify-content: start !important;
    }
}
@media (min-width: 2052px) and (max-width: 2732px) {
    .searchBar {
        width: 60%;
    }
}
@media (min-width: 2733px) and (max-width: 4102px) {
    .searchBar {
        width: 50%;
    }
}
@media (min-width: 4103px) and (max-width: 5464px) {
    .searchBar {
        width: 40%;
    }
} */

.dropdown_Btn > div {
    width: inherit;
    flex-basis: 100%;
}
#search_input {
    width: inherit;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: bold;
}
.filterscomp {
    /* position: absolute; */
    width: 106px;
    margin: -10px 0px 18px 13px;
    /* padding: 31px 0px; */
    height: 29px;
    left: 139px;
    top: 102px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #000000;
}
