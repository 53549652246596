.listHeader > div {
    border: 1px solid;
    font-size: 10px;
    padding: 4px;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

div#search-dash1 {
    width: 240px !important;
    margin-left: 7px !important;
    margin-bottom: 12px !important;
}
.css-140r5r1-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 36px !important;
    width: 100% !important;
    margin-left: -8px;
    margin-top: 7px;
}

.alert-text {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.017em;
    text-align: center;
    padding-top: 15px;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    background: "white";
}
.filter_alert {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
}
#cancelButton {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    padding: 6px 16px;
    gap: 8px;
    border-radius: 4px !important;
    border: 1px 0px 0px 0px !important;
    opacity: 1 !important;
}
button#cancelButton {
    width: auto;
    margin-top: 4px;
    height: 38px !important;
    text-align: center;
}
button#Apply,
button#Yes {
    padding: 6px 16px !important;
    gap: 8px;
    border-radius: 4px !important;
    background: #0b5cd7 !important;
    opacity: 1 !important;
}
