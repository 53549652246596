.editRippleIcon {
    height: 2rem;
    width: 2rem;
}

.recureRippleIcon {
    height: 1.7rem;
    width: 1.7rem;
}

.linkRippleIcon {
    height: 2.5rem;
    width: 2.5rem;
}

.shareRippleToSpaceIcon {
    height: 2rem;
    width: 2rem;
}

.shareRippleIcon {
    height: 1.5rem;
    width: 1.5rem;
}

.btnGroup {
    display: flex;
    flex-direction: row;
    width: 15rem;
    justify-content: space-evenly;
}

.rippleLogRowTitle:hover {
    text-decoration: underline;
    color: #0798ea;
}
.rippleLogRowTitleNew:hover {
    text-decoration: underline;
    color: #0798ea;
}
.wkLogRowTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.15px;
    white-space: pre;
    color: rgba(0, 0, 0, 0.87);
}
.rippleLogRowTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.15px;
    white-space: pre;
    color: rgba(0, 0, 0, 0.87);
}

.filterBtn {
    display: flex !important;
    align-items: center !important;
    padding: 5px !important;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 8rem;
    max-width: 11rem;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    background: white !important;
    border-radius: 0px !important;
    border: none !important;
    margin: 0px !important;
    margin-left: 10px !important;
    height: 40px !important;
}

.inlineSelectboxLabel {
    display: flex;
    margin: 0px;
    align-items: center;
}

.rippleLog_header {
    margin-left: 2rem;
    height: 10%;
    display: flex;
    align-items: center;
    /* position: sticky; */
    top: 0;
    background: white;
}

.cur_pointer {
    cursor: pointer;
}
