.share {
    width: 5rem;
    height: 2.5rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    color: #ffffff;
    border-radius: 10px;
    /* margin-bottom: 1rem; */
}

.rippleViewContainer {
    width: 42rem;
    height: 25rem;
    overflow: auto;
}

.chat_Content_icon {
    height: 1.5rem;
    width: auto;
    /* margin-right: 0.5rem; */
    /* background: white; */
}

.header {
    margin-left: 24px !important;
}
