.compare-container {
    display: flex;
    flex-direction: column;
}
.filter-container {
    padding: 4px;
}
.filters {
    width: 100%;
    padding: 4px;
}
.sub-containers {
    background: #ffffff;
    box-shadow: 1px 1px 20px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    align-items: center;
    margin: 1% 1% 1% 2%;
}
